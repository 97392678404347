export enum scratchCardStatusEnum {
  Created = "created",
  Active = "active",
}

export enum scratchCardUserStatusEnum {
  RewardAssigned = "rewardAssigned",
  Redeemed = "redeemed",
  Claimed = "claimed",
}

export enum scratchCardTypeEnum {
  Digital = "digital",
  Physical = "physical",
  PhysicalTest = "physical-test",
}
