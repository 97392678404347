import React, { useRef, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

interface CropperDialogProps {
  open: boolean;
  onClose: () => void;
  onCropSave: (croppedImage: File) => void;
  imageUrl: string;
  cropMode: string;
}

const CropperDialog = ({ open, onClose, onCropSave, imageUrl, cropMode }: CropperDialogProps) => {
  const cropperRef: any = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const adjustCropBox = () => {
      if (cropperRef.current) {
        const cropper = cropperRef.current.cropper;
        const containerData = cropper.getContainerData();

        if (cropMode === "profile") {
          const newSize = Math.min(containerData.width, containerData.height);
          cropper.setAspectRatio(1);
          cropper.setCropBoxData({
            left: (containerData.width - newSize) / 2,
            top: (containerData.height - newSize) / 2,
            width: newSize,
            height: newSize,
          });
        } else if (cropMode === "cover") {
          const newWidth = containerData.width;
          const desiredHeight = newWidth / 4.5;

          cropper.setAspectRatio(4.5);
          cropper.setCropBoxData({
            left: 0,
            top: (containerData.height - desiredHeight) / 2,
            width: newWidth,
            height: desiredHeight,
          });
        }
      }
    };

    if (open && imageUrl) {
      setTimeout(adjustCropBox, 100);
    }
  }, [open, imageUrl, cropMode]);

  const handleCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      canvas.toBlob((blob: any) => {
        if (blob) {
          const croppedFile = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
          onCropSave(croppedFile);
        }
      }, "image/jpeg");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <Box>
          <Cropper
            src={imageUrl}
            style={{ width: "100%" }}
            guides={false}
            viewMode={1}
            background={false}
            autoCropArea={1}
            ref={cropperRef}
            cropBoxResizable={true} // Disable resizing
            cropBoxMovable={true} // Allow moving the crop box
            dragMode="move" // Enable moving the image within the crop box
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCrop} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropperDialog;
