import ImageNotFound from "../assets/images/image-not-found.png";
import RipPackImage from "../assets/images/ripPack.png";

export function getImageUrlFromReward(rewardDetails: any): string {
  if (rewardDetails?.type === "RIP Pack") {
    return RipPackImage;
  }

  const imageUrl = rewardDetails?.imageUrl;
  const _id = rewardDetails?._id;

  if (!imageUrl && !_id) return ImageNotFound;

  const s3Path = `${"reward"}-images/${imageUrl || _id}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for reward`, { s3Path, cloudfrontPrefix });
    return ImageNotFound;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}

export function getNameFromReward(rewardDetails: any): string {
  if (rewardDetails?.type === "RIP Pack") {
    return "Rip Pack";
  }
  return rewardDetails?.name || "Reward Name";
}
