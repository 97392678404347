import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { contactUsApi } from "../apis/ContactUsApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import BannerHeader from "../components/common/BannerHeader";
import orangeBanner from "../assets/images/orange-banner.png";
import contactUsbannerIcon from "../assets/images/contact-us-banner-icon.png";
import messageIconsBackground from "../assets/images/message-icons-background.png";

export default function ContactUs() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  async function handleFormSubmit() {
    try {
      await contactUsApi.sendContactUsMessage(formData);
      dispatch(openSnackbar({ message: "Message saved successfully", severity: "success" }));
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to save message", severity: "error" }));
    }
  }

  return (
    <Stack sx={{ alignItems: "center", mt: 12 }}>
      <BannerHeader
        bannerBackground={orangeBanner}
        bannerDecoration={messageIconsBackground}
        bannerIcon={contactUsbannerIcon}
        bannerText="Contact Us"
      />

      <Typography variant="body1" textAlign={"center"} mt={3} p={2} gutterBottom>
        If you have any questions or are experiencing any issues, please fill out the form below and click "SUBMIT".
      </Typography>

      <Box
        sx={{
          width: { xs: "90%", sm: "25rem" },
          p: 5,
          mt: 2,
          mb: 2,
          boxSizing: "border-box",
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "1rem",
        }}
        component={Paper}
      >
        <Typography variant="h6" color="primary.main" textAlign={"center"} gutterBottom>
          How can we help?
        </Typography>

        <TextField label="YOUR NAME" variant="outlined" name="name" fullWidth onChange={handleChange} />
        <TextField label="EMAIL ADDRESS" variant="outlined" name="email" fullWidth onChange={handleChange} />
        <TextField label="SUBJECT" variant="outlined" name="subject" fullWidth onChange={handleChange} />
        <TextField
          label="MESSAGE"
          variant="outlined"
          name="message"
          fullWidth
          multiline
          rows={4}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          sx={{
            marginTop: 2,
            backgroundColor: "#24c477",
            color: "white",
            padding: "0.8rem 1.5rem",
            "&:hover": { backgroundColor: "#1f9c61" },
            lineHeight: 1,
          }}
          onClick={handleFormSubmit}
        >
          SUBMIT
        </Button>
      </Box>
    </Stack>
  );
}
