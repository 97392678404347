import Avatar from "@mui/material/Avatar";
import {
  Stack,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { setUserId } from "../redux/slices/user";
import { loginApi } from "../apis/LoginApi";
import { useDispatch } from "react-redux";
import WelcomeSection from "../components/common/WelcomeSection";
import OtpVerification from "../components/login/OtpVerification";
import { openSnackbar } from "../redux/slices/snackbar";
import Copyright from "../components/common/Copyright";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ForgotPasswordDialog from "../components/login/ForgotPasswordDialog";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { scratchCardApi } from "../apis/ScratchCardApi";
import WinnersList from "../components/common/WinnersList";
import { scratchCardUserStatusEnum } from "../types/scratchCardEnum";

interface LoginProps {
  showSignupOption?: boolean;
  skipVerification?: boolean;
  emailReceived?: string;
  couponCode?: string;
  redirectPathAfterLogin?: string;
}

export default function Login({
  showSignupOption = true,
  skipVerification,
  emailReceived,
  couponCode,
  redirectPathAfterLogin = "/user",
}: LoginProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [recentRewards, setRecentRewards] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const theme = useTheme();

  let twoFactorDisabled = Cookies.get("twoFactorDisabled") === "true"; // Check if twoFactorDisabled
  if (twoFactorDisabled) {
    skipVerification = true;
  }

  useEffect(() => {
    if (emailReceived) {
      setEmail(emailReceived); // Set email when emailReceived is provided
    }
  }, [emailReceived]);

  async function handleSubmit(event: any) {
    event.preventDefault();

    try {
      if (!email || !password) throw new Error("Email and password are required");
      // if (!validateEmail(email)) throw new Error("Please enter a valid email address");
      const res = await loginApi.verifyCredentials(email, password);
      const userId = res.user?._id;
      if (!userId) throw new Error("No user found");

      if (!skipVerification) {
        const otpResponse = await loginApi.generateOtpAndSendEmail({ email });
        if (!otpResponse) throw new Error("Error in sending email");
        setEmail(otpResponse?.email);
        dispatch(openSnackbar({ message: "Otp sent successfully", severity: "success" }));
        setIsVerified(true);
      } else {
        const res = await loginApi.signInUser(email, password);

        if (!res?.success) throw new Error("Invalid credentials");
        const userId = res?.user?._id;
        const jwtToken = res?.user?.jwtToken;
        if (!userId) throw new Error("No user found");
        if (!jwtToken) throw new Error("Authorization failed");
        dispatch(setUserId({ userId, jwtToken }));
        navigate(redirectPathAfterLogin || "/user");
      }
    } catch (err: any) {
      dispatch(openSnackbar({ message: "Login failed ", severity: "error" }));
      console.error(err);
    }
  }

  useEffect(() => {
    if (isOtpVerified) {
      loginApi
        .signInUser(email, password)
        .then((res) => {
          if (rememberMe) {
            Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
          }

          if (!res?.success) throw new Error("Invalid credentials");
          const userId = res?.user?._id;
          const jwtToken = res?.user?.jwtToken;
          if (!userId) throw new Error("No user found");
          if (!jwtToken) throw new Error("Authorization failed");
          dispatch(setUserId({ userId, jwtToken }));
          navigate(redirectPathAfterLogin || "/user");
        })
        .catch((err) => {
          dispatch(openSnackbar({ message: "Login failed ", severity: "error" }));
          console.error(err);
          setIsOtpVerified(false);
        });
    }
  }, [isOtpVerified, dispatch, email, password, navigate, rememberMe, redirectPathAfterLogin]);

  useEffect(() => {
    async function fetchRecentRewards() {
      const { scratchCardUsers, total } = await scratchCardApi.getPaginatedScratchCardUsers({
        page: 0,
        limit: 5,
        status: [scratchCardUserStatusEnum.Claimed, scratchCardUserStatusEnum.RewardAssigned],
        sortKey: "updatedAt",
        sortDirection: "-1",
        populate: true,
      });

      setRecentRewards(scratchCardUsers);
    }
    if (!showSignupOption) fetchRecentRewards();
  }, [showSignupOption]);

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ minHeight: "100vh", flexDirection: { xs: "column", sm: "row" } }}>
        <Box
          sx={{
            background: "linear-gradient(to bottom, #f0a725, #f05725)",
            pt: "4.5rem",
            pb: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          {showSignupOption ? (
            <WelcomeSection title="Welcome" page="login" />
          ) : (
            <WinnersList recentRewards={recentRewards} />
          )}
        </Box>
        <Box
          component={Paper}
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: { sm: "center", xs: "flex-start" },
            mt: { xs: 2, sm: 0 },
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{
              my: { xs: 0, sm: 8 },
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                transition: "transform 0.3s ease, opacity 0.3s ease",
                transform: !isOtpVerified ? "scale(1)" : "scale(1.2)",
                opacity: !isOtpVerified ? 1 : 0.7,
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
                {!isOtpVerified ? <LockOutlinedIcon /> : <LockOpenIcon />}
              </Avatar>
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {!isVerified ? (
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address or Username"
                  name="email"
                  autoComplete="email"
                  value={email}
                  autoFocus
                  disabled={!!emailReceived}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!twoFactorDisabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        onChange={() => setRememberMe(!rememberMe)}
                        checked={rememberMe}
                      />
                    }
                    label="Remember me"
                  />
                )}
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign In
                </Button>
                <Button onClick={() => setShowForgotPassword(true)} sx={{ textTransform: "none" }}>
                  Forgot Password
                </Button>

                {!showSignupOption && (
                  <Box sx={{ mt: 3, textAlign: "left" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                      New here?{" "}
                      <Link
                        onClick={() => navigate("/signup")}
                        sx={{ cursor: "pointer", color: "black", textDecoration: "underline" }}
                      >
                        Create an Account
                      </Link>
                    </Typography>
                    <Typography variant="body2" color="textPrimary" sx={{ mt: 2, fontWeight: "bold" }}>
                      Do I need to create an account to spin the wheel?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Yes, you will need to create an account to be able to spin the Shock’em Reward wheel. By creating
                      an account with us, it allows us to give our users the access they need to receive their reward.
                    </Typography>
                    <Typography variant="body2" color="textPrimary" sx={{ mt: 2, fontWeight: "bold" }}>
                      What information is needed to create an account?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      The only information needed to create an account is your email address. From there you just need
                      to create a username and password. That’s it!
                    </Typography>
                  </Box>
                )}

                <Copyright sx={{ mt: 5 }} />
              </Box>
            ) : (
              <OtpVerification email={email} isOtpVerified={isOtpVerified} setIsOtpVerified={setIsOtpVerified} />
            )}
          </Box>
        </Box>
      </Stack>
      <ForgotPasswordDialog open={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    </ThemeProvider>
  );
}
