import React from "react";
import { Box, Typography } from "@mui/material";

export default function CustomBlurOverlay({ children }: { children: React.ReactNode }) {
  return (
    <Box
      className="coming-soon-overlay"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      {children || (
        <Typography
          variant="h5"
          sx={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          No Content
        </Typography>
      )}
    </Box>
  );
}
