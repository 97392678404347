import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ContactUsText({ styleObj }: { styleObj?: any }) {
  return (
    <Stack sx={{ alignItems: "center", p: 2, ...styleObj }}>
      <Typography variant="body2">
        If you have any questions or experience any issues during the redemption process, please&nbsp;
        <Box component={Link} target="_blank" to={"https://www.shockem.com/contact-us"}>
          Contact Us
        </Box>
      </Typography>
    </Stack>
  );
}
