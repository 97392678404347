import { Box, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WelcomeTextImg from "../../assets/images/welcome-text.png";
import BotsTextImg from "../../assets/images/bots-text.png";
interface WelcomeSectionProps {
  title: string;
  page: string;
}

export default function WelcomeSection({ title, page }: WelcomeSectionProps) {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: "center", marginTop: 10 }}>
      <Stack sx={{ gap: 1, alignItems: "center" }}>
        <Box component={"img"} src={WelcomeTextImg} sx={{ width: { xs: "15rem", sm: "25rem" } }} />
        <Box component={"img"} src={BotsTextImg} sx={{ width: "20rem" }} />
      </Stack>
      <Typography component="h2" variant="h5" sx={{ mt: 2 }}>
        {
          {
            login: (
              <Typography
                sx={{
                  color: "black",
                  position: { xs: "relative", sm: "absolute" },
                  bottom: 0,
                  mb: { xs: 0, sm: 2 },
                  ml: { xs: 1, sm: 7 },
                  mt: { xs: 5 },
                }}
              >
                Don't have an account?{" "}
                <Link
                  onClick={() => navigate("/signup")}
                  sx={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
                >
                  Create an Account
                </Link>
              </Typography>
            ),
            signup: (
              <Typography sx={{ mb: { xs: 1, sm: 0 } }}>
                Already have an account?{" "}
                <Link
                  onClick={() => navigate("/login")}
                  sx={{ cursor: "pointer", color: "black", textDecoration: "underline" }}
                >
                  Login
                </Link>
              </Typography>
            ),
          }[page || "login"]
        }
      </Typography>
    </Box>
  );
}
