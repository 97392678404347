import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { userApi } from "../../apis/UserApi";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { loginApi } from "../../apis/LoginApi";

interface EditEmailProps {
  userDetails: any;
  updateUserData?: any;
  onClose: any;
}

export default function EditProfileEmail({ userDetails, updateUserData, onClose }: EditEmailProps) {
  const dispatch = useDispatch();
  const [otpEmailSent, setOtpEmailSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  async function handleSave() {
    try {
      if (!otpEmailSent) {
        const res = await loginApi.generateOtpAndSendEmail({ email: emailAddress });
        if (!res?.success) throw new Error("Error in sending email");
        dispatch(openSnackbar({ message: "OTP sent successfully", severity: "success" }));
        setOtpEmailSent(true);
      } else if (!otpVerified) {
        const res = await loginApi.verifyOtp(emailAddress, otp);
        if (!res?.success) throw new Error("Invalid verification code");
        const updatedProfile = { emailAddress };
        const filter = { _id: userDetails.userId };
        const formData = new FormData();
        formData.append("filter", JSON.stringify(filter));
        formData.append("userObjUpdates", JSON.stringify(updatedProfile));
        const updateRes = await userApi.updateUserDetails(formData);
        if (!updateRes?.success) throw new Error("Error in updating profile");
        setOtpVerified(true);
        if (updateUserData) updateUserData(updateRes?.user);
        dispatch(openSnackbar({ message: "User Email updated successfully", severity: "success" }));
      }
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "Error in updating email ", severity: "error" }));
    }
  }

  useEffect(() => {
    setEmailAddress(userDetails?.emailAddress);
  }, [userDetails?.emailAddress]);

  return (
    <React.Fragment>
      <DialogTitle>
        <React.Fragment>
          <IconButton component="label">
            <ArrowBack onClick={() => onClose()} />
          </IconButton>
          Edit Email
        </React.Fragment>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4} sx={{ position: "relative", p: 2 }}>
          <TextField
            fullWidth
            label="New Email Address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            disabled={otpEmailSent}
          />
          {otpEmailSent && (
            <TextField
              fullWidth
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={otpVerified}
            />
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {(!otpEmailSent || !otpVerified) && (
          <Button onClick={handleSave} color="primary">
            {!otpEmailSent ? "Send OTP" : !otpVerified ? "Verify" : ""}
          </Button>
        )}
      </DialogActions>
    </React.Fragment>
  );
}
