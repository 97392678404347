import { createSlice } from "@reduxjs/toolkit";
import { getUserIdFromToken, removeAuthCookies, setAuthCookies } from "../../utils/auth";

const initialState = {
  userId: getUserIdFromToken(),
  emailAddress: "",
  interests: [],
  profileImage: "",
  userName: "",
  stepProgress: { type: "", activeStep: "" },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
      if (action.payload.jwtToken) {
        const jwtToken = action.payload.jwtToken;
        setAuthCookies(jwtToken);
      }
    },

    setUser: (state, action) => {
      state.userId = action.payload.userId || state.userId;
      state.emailAddress = action.payload.emailAddress || state.emailAddress;
      state.interests = action.payload.interests || state.interests;
      state.profileImage = action.payload.profileImage || state.profileImage;
      state.userName = action.payload.userName || state.userName;
      state.stepProgress = action.payload.stepProgress || state.stepProgress;
    },

    logoutUser: (state) => {
      state.userId = null;
      localStorage.removeItem("jwtToken");
      removeAuthCookies();
    },
  },
});

export const { setUserId, logoutUser, setUser } = userSlice.actions;
export default userSlice.reducer;
