export function validatePassword(password: string, confirmPassword: string) {
  const passwordRequirements = {
    length: password.length >= 8,
    // specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    // upperLower: /[a-z]/.test(password) && /[A-Z]/.test(password),
    number: /\d/.test(password),
  };
  const isPasswordValid =
    passwordRequirements.length &&
    // passwordRequirements.specialChar &&
    // passwordRequirements.upperLower &&
    passwordRequirements.number &&
    password === confirmPassword;
  return {
    isPasswordValid,
    passwordRequirements,
  };
}

export function validateUserName(username: string) {
  // Check if the username is between 2 and 16 characters
  const isValidLength = username.length >= 2 && username.length <= 16;
  return isValidLength;
}

export function validateEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function validateSocialMediaLink(link: string) {
  const urlRegex = /^(https?:\/\/)?[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return urlRegex.test(link);
}
