import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Avatar,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { userApi } from "../../apis/UserApi";
import { useNavigate } from "react-router-dom";
import { FilePreview } from "../../types/common";
import {
  getDescriptionFromUser,
  getExternalLinksFromUser,
  getImageUrlFromProfileImageField,
  getInterestsFromUser,
  getNextStep,
  isFinalStep,
} from "../../utils/user";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import EditProfileSocials from "./EditProfileSocials";
import EditProfileEmail from "./EditProfileEmail";
import EditProfileInterests from "./EditProfileInterests";
import ImagePreview from "./ImagePreview";
import { ZoomIn } from "@mui/icons-material";
import UserCoverImage from "../user/UserCoverImage";
import { setUser } from "../../redux/slices/user";
import CropperDialog from "./CropperDialog";
import { DialogTitle } from "@mui/material";
import EditProfilePhoneNumber from "./EditProfilePhoneNumber";
import { UserStepEnum } from "../../types/userStageEnum";

interface EditProfileProps {
  open: boolean;
  onClose: () => void;
  userData: any;
  login?: boolean;
  steps?: any[];
  updateUserData?: (user: any) => void;
  closeOnSave?: boolean;
}

export default function EditProfile({
  open,
  onClose,
  userData,
  updateUserData,
  login,
  steps = [],
  closeOnSave = false,
}: EditProfileProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStepProgress = useSelector((state: any) => state.user?.stepProgress);

  const [userId, setUserId] = useState("");
  const [profileImage, setProfileImage] = useState<FilePreview>();
  const [coverImage, setCoverImage] = useState<FilePreview>();
  const [editEmailOn, setEditEmailOn] = useState(false);
  const [editPhoneNumberOn, setEditPhoneNumberOn] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [description, setDescription] = useState("");
  const [interests, setInterests] = useState<Array<string>>([]);
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState<any>({});
  const [socialMediaLinksValidation, setSocialMediaLinksValidation] = useState<any>({
    twitter: "",
    instagram: "",
    website: "",
  });
  const [croppingDialogOpen, setCroppingDialogOpen] = useState<boolean>(false);
  const [aspect, setAspect] = useState<string>("");
  const [croppingImage, setCroppingImage] = useState<string>("");

  function handleFileUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const previewUrl = URL.createObjectURL(file);
    setCroppingImage(previewUrl);
    setCroppingDialogOpen(true);
    setAspect("profile");
  }

  function handleCoverImageFileUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const newFile = { file: file, name: file.name, previewUrl: URL.createObjectURL(file) };
    setCoverImage(newFile);
    setCroppingImage(newFile.previewUrl);
    setCroppingDialogOpen(true);
    setAspect("cover");
  }

  function handleCropSave(croppedImage: File) {
    const newFile = { file: croppedImage, name: croppedImage.name, previewUrl: URL.createObjectURL(croppedImage) };
    if (aspect === "profile") setProfileImage(newFile);
    if (aspect === "cover") setCoverImage(newFile);
    setCroppingDialogOpen(false);
  }

  async function handleShouldUpdateStepProgress() {
    const updateStepProgress = profileImage && coverImage;
    return updateStepProgress;
  }

  async function handleSave() {
    const fullSocialMediaLinks = { ...socialMediaLinks };
    Object.keys(fullSocialMediaLinks).forEach((key) => {
      if (!fullSocialMediaLinks[key].startsWith("http")) {
        fullSocialMediaLinks[key] = "https://" + fullSocialMediaLinks[key];
      }
    });

    const updatedProfile: any = {
      userName: profileName,
      description,
      externalLinks: fullSocialMediaLinks,
      interests,
    };

    if (
      (await handleShouldUpdateStepProgress()) &&
      userStepProgress?.activeStep &&
      userStepProgress?.activeStep === UserStepEnum.UpdateUserInfo
    ) {
      updatedProfile["stepProgress.activeStep"] = getNextStep(
        userStepProgress?.activeStep,
        userStepProgress?.type
      ).nextStep;
      updatedProfile["stepProgress.stepTimestamp"] = null;
    }
    const filter = userId ? { _id: userId } : { emailAddress: userData?.emailAddress };
    const formData = new FormData();
    formData.append("filter", JSON.stringify(filter));
    formData.append("userObjUpdates", JSON.stringify(updatedProfile));
    if (profileImage) formData.append("profileImage", profileImage.file);
    if (coverImage) formData.append("coverImage", coverImage.file);
    try {
      const res: any = await userApi.updateUserDetails(formData);
      if (!res?.success) throw new Error("Error in updating profile");
      if (updateUserData) updateUserData(res?.user);
      if (!isFinalStep(res?.user?.stepProgress?.activeStep, res?.user?.stepProgress?.type))
        dispatch(setUser(res?.user));
      dispatch(openSnackbar({ message: "User updated successfully", severity: "success" }));
      if (closeOnSave) onClose();
      if (login) navigate("/login");
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "User update failed!", severity: "error" }));
    }
  }

  useEffect(() => {
    if (!userData) return;
    setProfileName(userData?.userName || "");
    setDescription(getDescriptionFromUser(userData));
    setUserId(userData?.userId || "");
    setInterests(getInterestsFromUser(userData));
    setSocialMediaLinks(getExternalLinksFromUser(userData, false));
  }, [userData]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {/* Overlay for more balanced blending */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "90%",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0)", // Adjust for balance
        }}
      />

      {/* Main content without backdrop-filter for clarity */}
      {!editEmailOn && !editPhoneNumberOn ? (
        <Box sx={{ position: "relative", zIndex: 2 }}>
          <DialogTitle>Edit Profile</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <React.Fragment>
                <Box position={"relative"}>
                  <UserCoverImage
                    editMode={true}
                    userData={userData}
                    editedCoverImage={coverImage}
                    onEdit={(e) => handleCoverImageFileUpload(e)}
                  />
                  <Stack alignItems={"center"} sx={{ zIndex: 2 }}>
                    <Box
                      position={"absolute"}
                      sx={{
                        top: "84%",
                        left: "13%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Avatar
                        src={profileImage?.previewUrl || getImageUrlFromProfileImageField(userData?.profileImage)}
                        sx={{
                          width: { xs: "15vw", sm: 80, md: 85 },
                          height: { xs: "15vw", sm: 80, md: 85 },
                          borderRadius: "50%",
                          border: "2px solid #1976d2",
                          boxShadow: "0 0 4px 3px grey",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setImagePreview(true);
                        }}
                        tabIndex={0}
                      />
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 1,
                          bgcolor: "white",
                          boxShadow: 1,
                          fontSize: { xs: "1rem", sm: "1.2rem" },
                          padding: { xs: 0.3, sm: 0.5 },
                        }}
                      >
                        <Edit color="secondary" fontSize="inherit" />
                        <input type="file" hidden accept="image/*" onChange={handleFileUpload} />
                      </IconButton>
                    </Box>
                  </Stack>
                </Box>
                <Box
                  component={Paper}
                  p={2}
                  boxShadow={3}
                  borderRadius={2}
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  <Tooltip title={userData?.emailAddress} placement="top">
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="primary.main"
                      sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                    >
                      {userData?.emailAddress}
                    </Typography>
                  </Tooltip>
                  <IconButton onClick={() => setEditEmailOn(!editEmailOn)}>
                    <Edit color="secondary" />
                  </IconButton>
                </Box>
                {/* <Box
                  component={Paper}
                  p={2}
                  boxShadow={3}
                  borderRadius={2}
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={userData?.phoneNumber} placement="top">
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="primary.main"
                      sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                    >
                      {userData?.phoneNumber}
                    </Typography>
                  </Tooltip>
                  <IconButton onClick={() => setEditPhoneNumberOn(!editPhoneNumberOn)}>
                    <Edit color="secondary" />
                  </IconButton>
                </Box> */}
                <TextField
                  fullWidth
                  label="Profile Name"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <EditProfileInterests interests={interests} setInterests={setInterests} />
                <EditProfileSocials
                  socialMediaLinks={socialMediaLinks}
                  setSocialMediaLinks={setSocialMediaLinks}
                  socialMediaLinksValidation={socialMediaLinksValidation}
                  setSocialMediaLinksValidation={setSocialMediaLinksValidation}
                />
              </React.Fragment>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Box>
      ) : editEmailOn ? (
        <EditProfileEmail
          userDetails={userData}
          updateUserData={updateUserData}
          onClose={() => setEditEmailOn(false)}
        />
      ) : (
        <EditProfilePhoneNumber
          userDetails={userData}
          updateUserData={updateUserData}
          onClose={() => setEditPhoneNumberOn(false)}
        />
      )}

      <ImagePreview
        imageUrl={profileImage?.previewUrl || getImageUrlFromProfileImageField(userData?.profileImage)}
        open={imagePreview}
        setOpen={setImagePreview}
        title="Profile Image"
      />

      <CropperDialog
        open={croppingDialogOpen}
        onClose={() => {
          setCroppingDialogOpen(false);
          setCroppingImage("");
        }}
        onCropSave={handleCropSave}
        imageUrl={croppingImage}
        cropMode={aspect}
      />
    </Dialog>
  );
}
