import { getItemPaginationProps } from "../types/item";
import BaseAPI from "./BaseApi";

class CollectionApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/collection" });
  }

  async getCollectionLeaderBoard({ ownerId }: { ownerId?: string }) {
    try {
      const result = await this.axios.get("/leaderBoard", { params: { ownerId } });
      if (!result.data || !result.data.success || !result.data.collectionLeaderBoardMap)
        throw new Error("Invalid response from server");
      return result.data.collectionLeaderBoardMap || null;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@CollectionApi.getCollectionLeaderBoard:", { response: response }, { error: error.message });
      throw new Error(`@CollectionApi.getCollectionLeaderBoard: ${response}`);
    }
  }
}

export const collectionApi = new CollectionApi();
