import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { loginApi } from "../../apis/LoginApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";

export default function ForgotPasswordDialog({ open, onClose }: any) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  async function handleSendEmail() {
    try {
      await loginApi.sendEmailForForgotPassword({ email });
      dispatch(openSnackbar({ message: "Please check your email", status: "success" }));
    } catch (error: any) {
      console.error("Error in ForgotPasswordDialog.handleSendEmail: ", error);
      dispatch(openSnackbar({ message: error.message, status: "error" }));
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={2}>
          <Typography>Please fill in the email address</Typography>
          <Stack direction={"row"} gap={2}>
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
            <Button variant="contained" onClick={() => handleSendEmail()}>
              Send
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
