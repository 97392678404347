import { Edit, Info, Remove } from "@mui/icons-material";
import { Box, Divider, Stack, Typography, Paper, IconButton, Chip, Tooltip, Button } from "@mui/material";
import ProfilePicture from "./ProfilePicture";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SocialIcons from "./SocialIcons";
import UserInterests from "./UserInterests";
import { useIsMobile } from "../../hooks/useIsMobile";
import { getDescriptionFromUser, shockemUserDefaultValues } from "../../utils/user";
import { formatDate } from "../../utils/time";
import { FaEdit } from "react-icons/fa";

interface OwnerInfoProps {
  userDetails: any;
  setEditMode: any;
}

export default function OwnerInfo({ userDetails, setEditMode }: OwnerInfoProps) {
  const isMobile = useIsMobile();

  const currentUser = useSelector((state: any) => state.user);
  const currentUserId = currentUser?.userId;
  const isOwner = userDetails?._id === currentUserId;

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "100%" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
      gap={"0.4rem"}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          // fontWeight={600}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: { xs: "2.5rem", sm: "2.5rem" },
            gap: "0.5rem",
            lineHeight: 1,
          }}
        >
          {userDetails?.userName || ""}
          {isOwner && (
            <Button
              variant="outlined"
              sx={{
                border: "0.1rem solid grey",
                borderRadius: "1.3rem",
                color: "black",
                padding: "0.4rem 1rem",
                fontWeight: 600,
                fontSize: "1rem",
                textTransform: "none", // Prevent default uppercase transformation
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              onClick={() => setEditMode(true)}
            >
              {"Edit Profile"}
            </Button>
          )}
        </Box>

        {!isMobile && <SocialIcons userDetails={userDetails} />}
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          fontSize: { xs: "0.8rem", sm: "0.8rem" },
          lineHeight: 1,
        }}
      >
        Joined the Shock'em Community On {formatDate(userDetails?.accountCreatedAt)}
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          fontSize: { xs: "1.3rem", sm: "1.3rem" },
          lineHeight: 1.2,
          // fontWeight: 500,
        }}
      >
        {getDescriptionFromUser(userDetails)}
      </Box>

      <UserInterests userDetails={userDetails} />
    </Box>
  );
}
