import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, CircularProgress, IconButton, Paper } from "@mui/material";
import { ShockemUserType, userApi } from "../../apis/UserApi";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { validateEmail } from "../../utils/validation";
import { getNextStep, isFinalStep } from "../../utils/user";
import { setUser } from "../../redux/slices/user";
import { UserStepEnum } from "../../types/userStageEnum";
import { scratchCardStatusEnum } from "../../types/scratchCardEnum";
import { useNavigate } from "react-router-dom";

export default function ReferFriendPopup({
  styleObj,
  onClose,
  rewardPage = false,
  setReferralSent = () => {},
}: {
  styleObj?: any;
  onClose?: any;
  rewardPage?: boolean;
  setReferralSent?: any;
}) {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state: any) => state.user.userId);
  const userStepProgress = useSelector((state: any) => state.user?.stepProgress);
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState<ShockemUserType>({
    _id: "",
    emailAddress: "",
    verifiedEmail: false,
    referralsLeft: 0,
    userName: "",
  });

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState<string>("");
  const [sentReferrals, setSentReferrals] = useState<any[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [newEmail, setNewEmail] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        const userDetails = await userApi.getUserDetails({ userId: loggedInUserId });
        setUserDetails(userDetails);
      } catch (error) {
        dispatch(openSnackbar({ message: "Failed to fetch user details", severity: "error" }));
      }
    })();
  }, [dispatch, loggedInUserId]);

  useEffect(() => {
    (async () => {
      const defaultMessage = `${
        userDetails.userName || "Your friend"
      } has shared a reward with you through our Shock'em "Reward-A-Friend" program!`;
      setCustomMessage(defaultMessage);
      try {
        const referrals = await userApi.getReferrals({ userEmail: userDetails.emailAddress });
        setSentReferrals(referrals);
      } catch (error) {
        dispatch(openSnackbar({ message: "Failed to fetch referrals", severity: "error" }));
      }
    })();
  }, [userDetails, userDetails.referralsLeft, dispatch]);

  // Handler for sending a referral
  const handleSendReferral = async () => {
    if (!email || !name) {
      dispatch(openSnackbar({ message: "Please fill in all fields", severity: "error" }));
      return;
    }
    if (!validateEmail(email)) {
      dispatch(openSnackbar({ message: "Please enter a valid email address", severity: "error" }));
      return;
    }
    setLoading(true);
    try {
      const res = await userApi.sendReferalEmail({
        referralName: name,
        referralEmail: email,
        userEmail: userDetails.emailAddress,
        customMessage: customMessage,
      });
      if (res.success) {
        if (userStepProgress?.activeStep && userStepProgress?.activeStep === UserStepEnum.ReferAFriend) {
          const filter = { _id: loggedInUserId };
          const updatedProfile = {
            "stepProgress.activeStep": getNextStep(userStepProgress?.activeStep, userStepProgress?.type).nextStep,
            "stepProgress.stepTime": Date.now(),
          };
          const formData = new FormData();
          formData.append("filter", JSON.stringify(filter));
          formData.append("userObjUpdates", JSON.stringify(updatedProfile));
          const updateUserStep = await userApi.updateUserDetails(formData);
          if (!isFinalStep(updateUserStep?.user?.stepProgress?.activeStep, updateUserStep?.user?.stepProgress?.type))
            dispatch(setUser(updateUserStep?.user));
        }
        dispatch(openSnackbar({ message: "Referral Sent", severity: "success" }));
      }

      if (userDetails.referralsLeft && userDetails.referralsLeft > 0) {
        userDetails.referralsLeft -= 1;
      }
      setUserDetails(userDetails); // Decrease the number of referrals left
      setEmail(""); // Clear the email input
      setName(""); // Clear the name input
      setReferralSent(true);
    } catch (error: any) {
      console.error("Error sending referral:", error);
      dispatch(
        openSnackbar({ message: error.message || "Error sending referral! Please try again later", severity: "error" })
      );
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleResendReferral = async (referral: any) => {
    setLoading(true);
    try {
      const res = await userApi.sendReferalEmail({
        referralName: referral.referredName,
        referralEmail: referral.referredEmail,
        userEmail: userDetails.emailAddress,
        customMessage: "",
        resend: true,
      });
      if (res.success) {
        dispatch(openSnackbar({ message: "Referral Email has been Resent", severity: "success" }));
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({ message: error.message || "Error resending email! Please try again later", severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index: number, email: string) => {
    setEditIndex(index);
    setNewEmail(email);
  };
  const handleCancelEdit = () => {
    setEditIndex(null); // Exit edit mode without making any changes
  };

  const handleEmailUpdate = async (referral: any, newEmail: string) => {
    try {
      if (!validateEmail(newEmail)) {
        dispatch(openSnackbar({ message: "Please enter a valid email address", severity: "error" }));
        return;
      }
      setLoading(true);
      const res = await userApi.sendReferalEmail({
        referralName: referral.referredName,
        referralEmail: referral.referredEmail,
        userEmail: userDetails.emailAddress,
        customMessage: "",
        resend: true,
        newEmail,
      });
      if (res.success) {
        dispatch(openSnackbar({ message: "Referral Email has been Resent to the new Email", severity: "success" }));

        // Update the local state with the new email
        const updatedReferrals = [...sentReferrals];
        updatedReferrals[editIndex!] = { ...updatedReferrals[editIndex!], referredEmail: newEmail };
        setSentReferrals(updatedReferrals);
      }
      setEditIndex(null); // Exit edit mode
    } catch (error: any) {
      dispatch(openSnackbar({ message: "Error updating email: " + error.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { sm: "row", xs: "column" },
        justifyContent: "center",
        alignItems: { xs: "center", sm: "stretch" },
        width: { sm: "100%", xs: "100%" },
      }}
    >
      {/* Left: Referral form */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: "2rem",
          boxShadow: "10px 1rem 1rem 0.0rem grey",
          padding: "2rem",
          backgroundColor: "white",
          width: { sm: "60%", xs: "80%" },
          maxWidth: "100%",
        }}
      >
        <Typography variant="h5" sx={{ mb: 1, fontWeight: "bold", color: "primary.main" }}>
          Rewards Remaining: {userDetails.referralsLeft}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.2, textAlign: "left", width: "100%" }}>
          In the fields below, please input your friend's name and their email address.
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", gap: 1, width: "100%" }}>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.8rem",
              },
            }}
            label="Friend's Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.8rem",
              },
            }}
            label="Friend's Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography variant="body2" sx={{ mt: 1, color: "text.secondary", fontStyle: "italic", textAlign: "left" }}>
            We have created a prefilled email template as well to send to your friends, but feel free to edit this
            template if you prefer.
          </Typography>
          <TextField
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.8rem",
              },
            }}
            label="Email Template"
            multiline
            minRows={2}
            variant="outlined"
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
          />
        </Box>

        <Button
          variant="contained"
          sx={{
            marginTop: 2,
            backgroundColor: "#24c477",
            color: "white",
            padding: "0.8rem 1.5rem",
            "&:hover": { backgroundColor: "#1f9c61" },
          }}
          onClick={handleSendReferral}
          disabled={userDetails.referralsLeft === 0}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "REWARD MY FRIEND!"}
        </Button>

        <Box
          component="a"
          sx={{ textDecoration: "underline", color: "primary.main", cursor: "pointer", mt: 2, fontSize: "0.8rem" }}
          onClick={() => navigate("/user")}
        >
          I don't want to reward my friends
        </Box>
      </Box>

      {/* Right: Sent referrals status */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "0.5rem",
          marginLeft: { xs: "0rem", sm: "1rem", md: "2rem" },
          mt: { sm: 0, xs: 4 },
          borderRadius: "1rem",
          boxShadow: "10px 1rem 1rem 0.0rem grey",
          width: { sm: "40%", xs: "95%" },
          maxWidth: "100%",
        }}
      >
        <Typography variant="h5" sx={{ mt: 3, fontWeight: "bold", color: "primary.main" }}>
          Rewarded Friends
        </Typography>
        <Box
          sx={{
            width: "100%",
            overflowY: "auto",
            padding: "0.5rem",
            alignItems: "center",
          }}
        >
          {sentReferrals.length > 0 ? (
            sentReferrals.map((referral, index) => (
              <Paper
                key={index}
                sx={{
                  width: "90%",
                  padding: "0.8rem",
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "0.8rem",
                }}
              >
                {editIndex === index ? (
                  <>
                    {/* Editable email field */}
                    <TextField
                      variant="outlined"
                      size="small"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      sx={{ width: "75%" }}
                    />
                    <IconButton
                      onClick={() => handleEmailUpdate(referral, newEmail)}
                      disabled={loading}
                      color="primary"
                      sx={{ marginRight: 1 }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelEdit} disabled={loading} color="error">
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", width: "70%" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "90%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {referral.referredEmail}
                      </Typography>
                    </Box>
                    {referral.status === "accepted" ? (
                      <Box
                        sx={{
                          backgroundColor: "green",
                          color: "white",
                          padding: "0.3rem 0.5rem",
                          borderRadius: "0.3rem",
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </Box>
                    ) : (
                      <>
                        {" "}
                        <IconButton onClick={() => handleEdit(index, referral.referredEmail)} sx={{ marginLeft: 1 }}>
                          <EditIcon color="action" />
                        </IconButton>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "orange",
                            color: "white",
                            "&:hover": { backgroundColor: "#ff8c00" },
                          }}
                          onClick={() => handleResendReferral(referral)}
                          disabled={loading}
                        >
                          Resend
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Paper>
            ))
          ) : (
            <Typography variant="body2">No referrals sent yet.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
