import { useEffect } from "react";
import { setUser } from "../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { userApi } from "../../apis/UserApi";
import { openSnackbar } from "../../redux/slices/snackbar";

export default function DataProvider() {
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.user.userId);

  useEffect(() => {
    if (!userId) return;
    (async () => {
      try {
        const userDetails = await userApi.getUserDetails({ userId: userId });
        dispatch(
          setUser({
            userId: userDetails._id,
            emailAddress: userDetails.emailAddress,
            profileImage: userDetails.profileImage,
            stepProgress: userDetails.stepProgress,
          })
        );
      } catch (error: any) {
        console.error(error);
        dispatch(openSnackbar({ message: "Failed to fetch user details", severity: "error" }));
      }
    })();
  }, [userId, dispatch]);

  return null;
}
