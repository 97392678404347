export function formatDate(timestamp: string): string {
  if (!timestamp) return "Never";
  const date = new Date(timestamp);

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  return formattedDate === "Invalid Date" ? "Never" : formattedDate;
}

export function formatTime(timestamp: string): string {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return formattedTime === "Invalid Date" ? "N/A" : formattedTime;
}

export function timeDifference(givenTime: string) {
  if (!givenTime) return null;
  const now = new Date();
  const givenDate = new Date(givenTime);

  const differenceInSeconds = Math.floor((givenDate.getTime() - now.getTime()) / 1000);
  const absoluteDifference = Math.abs(differenceInSeconds);

  let result = "";

  if (absoluteDifference < 60) {
    result = `${absoluteDifference} second${absoluteDifference !== 1 ? "s" : ""} ${
      differenceInSeconds > 0 ? "from now" : "ago"
    }`;
  } else if (absoluteDifference < 3600) {
    const minutes = Math.floor(absoluteDifference / 60);
    result = `${minutes} minute${minutes !== 1 ? "s" : ""} ${differenceInSeconds > 0 ? "from now" : "ago"}`;
  } else if (absoluteDifference < 86400) {
    const hours = Math.floor(absoluteDifference / 3600);
    result = `${hours} hour${hours !== 1 ? "s" : ""} ${differenceInSeconds > 0 ? "from now" : "ago"}`;
  } else if (absoluteDifference < 604800) {
    const days = Math.floor(absoluteDifference / 86400);
    result = `${days} day${days !== 1 ? "s" : ""} ${differenceInSeconds > 0 ? "from now" : "ago"}`;
  } else {
    const weeks = Math.floor(absoluteDifference / 604800);
    result = `${weeks} week${weeks !== 1 ? "s" : ""} ${differenceInSeconds > 0 ? "from now" : "ago"}`;
  }

  return result;
}
