import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { loginApi } from "../apis/LoginApi";
import { useNavigate, useParams } from "react-router-dom";
import { openSnackbar } from "../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { userApi } from "../apis/UserApi";
import Signup from "./Signup";
import Login from "./Login";

export default function RedeemCouponRedirect() {
  const param = useParams<{ token: string }>();
  const token = param.token;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const loggedInUserId = useSelector((state: any) => state.user.userId);
  useEffect(() => {
    async function validateTokenAndRedirect() {
      try {
        if (!token) {
          dispatch(
            openSnackbar({
              message: "Invalid token! Sorry for the inconvenience.",
              severity: "error",
            })
          );
          return;
        }

        const decodedTokenInfo = await loginApi.decodeToken({ token });

        let email = decodedTokenInfo?.email;
        let couponCode = decodedTokenInfo?.couponCode;

        if (!email || !couponCode) {
          dispatch(
            openSnackbar({
              message: "Invalid token! Sorry for the inconvenience.",
              severity: "error",
            })
          );
          return;
        }

        setCouponCode(couponCode);
        setEmail(email);

        const userDetails = await userApi.getUserDetails({ email: email });

        if (!userDetails) {
          setShowSignup(true);
        } else {
          if ((userDetails._id = loggedInUserId)) navigate(`/redeem-scratch-card/${couponCode}`);
          else setShowLogin(true);
        }
      } catch (error: any) {
        if (error?.message && error.message === "Token expired") {
          dispatch(
            openSnackbar({
              message: "Link to redeem coupon expired. ",
              severity: "error",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: "Invalid token! Sorry for the inconvenience.",
              severity: "error",
            })
          );
        }

        return;
      }
    }

    validateTokenAndRedirect();
  }, [token]);

  return (
    <Box>
      {showLogin && couponCode && email && (
        <Login
          showSignupOption={false}
          skipVerification={true}
          emailReceived={email}
          couponCode={couponCode}
          redirectPathAfterLogin={`/redeem-scratch-card/${couponCode}`}
        />
      )}
      {showSignup && couponCode && email && (
        <Signup showLoginOption={false} skipVerification={true} emailReceived={email} couponCode={couponCode} />
      )}
    </Box>
  );
}
