import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export function useAuthBasedRedirect({
  redirectUrl,
  authBasedRedirect = true,
}: {
  redirectUrl?: string;
  authBasedRedirect?: boolean;
}) {
  const userId = useSelector((state: any) => state.user.userId);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authBasedRedirect) {
      if (userId && (location.pathname === "/login" || location.pathname === "/signup")) navigate("/user");
      else if (!userId && location.pathname !== "/signup") navigate("/login");
    }
  }, [userId, redirectUrl, navigate, location.pathname]);

  return null;
}
