import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import PasswordRequirement from "../components/signup/PasswordRequirement";
import { validatePassword } from "../utils/validation";
import { loginApi } from "../apis/LoginApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const token = new URLSearchParams(window.location.search).get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const passwordRequirements = validatePassword(password, confirmPassword).passwordRequirements;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  async function resetPassword() {
    try {
      if (!token) {
        dispatch(openSnackbar({ message: "No token found", severity: "error" }));
        return;
      }
      await loginApi.changePassword({ token, password });
      dispatch(openSnackbar({ message: "Password changed successfully", severity: "success" }));
      window.location.href = "/login";
    } catch (error: any) {
      console.error("Error in ResetPassword.resetPassword: ", error);
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  }
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Dialog open fullWidth maxWidth={"sm"}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <Stack gap={1} p={2}>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="New Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">Password must include:</Typography>
              <PasswordRequirement label={"At least 8 characters"} valid={passwordRequirements.length} />
              <PasswordRequirement label={"At least one number"} valid={passwordRequirements.number} />
              <PasswordRequirement
                label={"Passwords match"}
                valid={password === confirmPassword && passwordRequirements.length}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => resetPassword()}
            variant="contained"
            disabled={!validatePassword(password, confirmPassword).isPasswordValid}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
