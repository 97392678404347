import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TokenCard from "./TokenCard";

interface GridSectionProps {
  items: any;
}

export default function GridSection({ items }: GridSectionProps) {
  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <Grid container spacing={2}>
        {items.map((item: any, index: number) => (
          <Grid key={index} size={{ xs: 6, sm: 2.4 }}>
            <TokenCard item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
