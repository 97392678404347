import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./redux/store";
import User from "./pages/User";
import { Box, Stack, ThemeProvider, Typography } from "@mui/material";
import { lightTheme } from "./configs/muiTheme";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SnackbarProvider from "./components/provider/SnackbarProvider";
import Item from "./pages/Item";
import Home from "./pages/Home";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import DataProvider from "./components/provider/DataProvider";
import VerifyEmail from "./pages/VerifyEmail";
import CouponRewardWheel from "./pages/RedeemCoupon";
import RedeemCouponRedirect from "./pages/RedeemCouponRedirect";
import RedeemCoupon from "./pages/RedeemCoupon";
import ReferUser from "./components/user/ReferUser";
import ReferUserPage from "./pages/ReferUserPage";
import { useAuthBasedRedirect } from "./hooks/useAuthBasedRedirect";
import CollectionLeaderBoard from "./pages/CollectionLeaderBoard";
import ResetPassword from "./pages/ResetPassword";
import ContactUs from "./pages/ContactUs";

function CommonLayout({
  children,
  authBasedRedirect = true,
  showFooter = true,
  showFooterSocialIcons = true,
}: {
  children: React.ReactNode;
  authBasedRedirect?: boolean;
  showFooter?: boolean;
  showFooterSocialIcons?: boolean;
}) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header authBasedRedirect={authBasedRedirect} />
        <Box component="main" flexGrow={1}>
          {children}
        </Box>
        {showFooter && <Footer showFooterSocialIcons={showFooterSocialIcons} />}
      </Box>
    </ThemeProvider>
  );
}

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <CommonLayout>
          <Home />
        </CommonLayout>
      ),
    },
    {
      path: "/user/:userId",
      element: (
        <CommonLayout authBasedRedirect={false}>
          <User />
        </CommonLayout>
      ),
    },
    {
      path: "/user",
      element: (
        <CommonLayout>
          <User />
        </CommonLayout>
      ),
    },
    {
      path: "/verify-email",
      element: (
        <CommonLayout>
          <VerifyEmail />
        </CommonLayout>
      ),
    },
    {
      path: "/item/:collectionId/:itemId",
      element: (
        <CommonLayout>
          <Item />
        </CommonLayout>
      ),
    },
    {
      path: "/leaderBoard",
      element: (
        <CommonLayout>
          <CollectionLeaderBoard />
        </CommonLayout>
      ),
    },
    {
      path: "/login",
      element: (
        <CommonLayout showFooter={false}>
          <Login />
        </CommonLayout>
      ),
    },
    {
      path: "/signup",
      element: (
        <CommonLayout showFooterSocialIcons={false}>
          <Signup />
        </CommonLayout>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <CommonLayout authBasedRedirect={false}>
          <ResetPassword />
        </CommonLayout>
      ),
    },
    {
      path: "/refer-a-friend",
      element: (
        <CommonLayout>
          <ReferUserPage />
        </CommonLayout>
      ),
    },
    {
      path: "/redeem-scratch-card-redirect/:token",
      element: (
        <CommonLayout authBasedRedirect={false}>
          <RedeemCouponRedirect />
        </CommonLayout>
      ),
    },
    {
      path: "/redeem-scratch-card/:couponCode",
      element: (
        <CommonLayout showFooterSocialIcons={false}>
          <RedeemCoupon />
        </CommonLayout>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <CommonLayout>
          <ContactUs />
        </CommonLayout>
      ),
    },

    {
      path: "*",
      element: (
        <CommonLayout>
          <Box height={"5rem"} />
          <Stack gap={2} p={2}>
            <Typography variant="h4">404 Not found</Typography>
            <Typography>Sorry, the page you are looking for does not exist.</Typography>
          </Stack>
        </CommonLayout>
      ),
    },
  ]);

  return (
    <Provider store={store}>
      <DataProvider />
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}
