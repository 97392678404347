import BaseAPI from "./BaseApi";

class RewardApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/reward" });
  }

  async getRewardDetails({ rewardId }: { rewardId: string }) {
    try {
      const result = await this.axios.get("/details", {
        params: { rewardId },
      });
      if (!result.data || !result.data.rewardDetails) throw new Error("Invalid response from server");

      return result.data.rewardDetails[0];
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@RewardApi.getRewardDetails:", { response: response }, { error: error.message });
      throw new Error(`@RewardApi.getRewardDetails: ${response}`);
    }
  }

  async getRewards({ eventId }: { eventId: number }) {
    try {
      const result = await this.axios.get("", {
        params: { eventId },
      });
      if (!result.data || !result.data.rewardsData) throw new Error("Invalid response from server");
      return result.data.rewardsData;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@RewardApi.getRewards:", { response: response }, { error: error.message });
      throw new Error(`@RewardApi.getRewards: ${response}`);
    }
  }
}

export const rewardApi = new RewardApi();
