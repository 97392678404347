import { Box, Button, Dialog, Typography } from "@mui/material";
import ShockemPopupBox from "../common/ShockemPopupBox";
import orangeBackgroundConfetti from "../../assets/images/orangeBackgroundConfetti.png";
import welcomeCursiveText from "../../assets/images/welcome-cursive-text.png";

import hiLogo from "../../assets/images/hi-logo.png";

interface WelcomeUserDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export default function WelcomeUserDialog({ open, onClose, onContinue }: WelcomeUserDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "1rem",
          overflow: "hidden",
        },
      }}
    >
      <ShockemPopupBox bannerImage={orangeBackgroundConfetti} bannerLogoImage={hiLogo}>
        <Box sx={{ textAlign: "center", color: "black" }}>
          <Box component="img" src={welcomeCursiveText} sx={{ width: "90%" }} alt="Shock'em Rewards" />

          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            to the
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 1,
            }}
          >
            Shock'em Community!
          </Typography>

          <Typography variant="body1" sx={{}}>
            We are happy to have you joining our Community! Please take a few moments to finish completing your profile
            to unlock all the features of Shock'em and start connecting with community.
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: 1,
              mb: 1,
              bgcolor: "Green",
              color: "white",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
            onClick={onContinue}
          >
            Continue
          </Button>
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
              mb: 1,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            I don’t want to connect with the Shock’em Community
          </Typography>
        </Box>
      </ShockemPopupBox>
    </Dialog>
  );
}
