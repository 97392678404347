import { Box, Typography } from "@mui/material";

interface BookmarkProps {
  text?: string;
  color?: string;
}

export default function CustomBookmark({ text = "New", color = "orange" }: BookmarkProps) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 10,
        width: 50,
        height: 50,
        backgroundColor: color,
        color: "white",
        textAlign: "center",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "0.75rem",
        clipPath: "polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%)",
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
}
