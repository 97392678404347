import { Box, Card, CardContent, Typography, Avatar } from "@mui/material";
import { getImageUrlFromProfileImageField } from "../../utils/user";
import winnersLogo from "../../assets/images/winners-logo.png"; // Adjust the path as needed
import { useIsMobile } from "../../hooks/useIsMobile";

const WinnersList = ({ recentRewards }: { recentRewards: any[] }) => {
  const isMobile = useIsMobile();
  return (
    <Box>
      {/* Logo and Title */}
      <Box sx={{ textAlign: "center", marginTop: 10 }}>
        <img src={winnersLogo} alt="Winners Logo" style={{ width: isMobile ? "20vw" : 140, marginBottom: 8 }} />
        <Typography variant="h2" fontWeight="bold" color="primary.main">
          RECENT WINNERS
        </Typography>
      </Box>

      {recentRewards.length > 0 ? (
        recentRewards.map((reward) => (
          <Box
            key={reward?._id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              padding: 1,
              color: "black",
              width: "100%",
              marginY: 1,
            }}
          >
            <Avatar
              src={getImageUrlFromProfileImageField(reward?.ownerUserId?.profileImage)}
              alt={reward?.ownerUserId?.userName}
              sx={{
                width: { xs: "13vw", sm: 60, md: 65 },
                height: { xs: "13vw", sm: 60, md: 65 },
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", width: "13rem", fontSize: "0.75rem" }} padding={1}>
              <Typography variant="h5" fontWeight="bold">
                {reward?.ownerUserId?.userName || "Anonymous"}
              </Typography>

              <Typography variant="body1" mt={1} color="white">
                {reward?.scratchCardId?.rewardId?.name || "N/A"}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="body1" color="grey.500" mt={3}>
          No recent rewards available.
        </Typography>
      )}
    </Box>
  );
};

export default WinnersList;
