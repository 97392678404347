import { Box, Card, CardMedia, Typography } from "@mui/material";
import ImageNotFoundImg from "../../../assets/images/image-not-found.png";
import { useDispatch, useSelector } from "react-redux";
import CustomBookmark from "../../common/CustomBookmark";
import { getImageUrlFromRipPack } from "../../../utils/rip-pack";
import CustomBlurOverlay from "../../common/CustomBlurOverlay";

interface RipPackCardProps {
  ripPackDetails: any;
  userScratchCardDetails: any;
}

export default function RipPackCard({ ripPackDetails, userScratchCardDetails }: RipPackCardProps) {
  return (
    <Card
      sx={{
        borderRadius: "0.5rem",
        boxShadow: 5,
        position: "relative",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          border: "2px solid orange",
          cursor: "pointer",
        },
        border: `3px solid grey`,
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "0.5rem",
          "&:hover .ripPack-image": {
            filter: "blur(4px)",
          },
          "&:hover .coming-soon-overlay": {
            opacity: 1,
          },
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          height: "100%",
        }}
      >
        <CardMedia
          component="img"
          alt="RipPack-image"
          sx={{ objectFit: "cover", aspectRatio: "1/1" }}
          image={getImageUrlFromRipPack(ripPackDetails, userScratchCardDetails) || ""}
          onError={(e: any) => (e.target.src = ImageNotFoundImg)}
          loading="lazy"
          className="ripPack-image"
          style={{ transition: "filter 0.3s ease-in-out" }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 63,
            left: -57,
            transform: "rotate(90deg)",
            width: 150,
            height: 50,
            backgroundColor: "orange",
            borderTopLeftRadius: "9.375rem",
            borderTopRightRadius: "9.375rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {ripPackDetails?.name || "Rip Pack"}
          </Typography>
        </Box>

        <CustomBlurOverlay>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Coming Soon
          </Typography>
        </CustomBlurOverlay>
      </Box>
    </Card>
  );
}
