export function getImageUrlFromSubscription(subscriptionDetails: any) {
  const imageUrl = subscriptionDetails?.imageUrl;
  const _id = subscriptionDetails?._id;

  if (!imageUrl && !_id) return null;

  const s3Path = `${"subscription"}-images/${imageUrl || _id}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for subscription`, { s3Path, cloudfrontPrefix });
    return null;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}
