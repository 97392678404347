import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Copyright(props: any) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: `calc(var(--website-font-size) * 0.5)`,

        width: "100%",
        height: `calc(var(--website-font-size) * (75 / 20))`,

        // UI Properties
        opacity: 1,

        textAlign: "center",
        font: `normal normal 500 calc(var(--website-font-size) * 30 / 20) / calc(var(--website-font-size) * 51 / 20) Roboto Condensed`,
        letterSpacing: `calc(var(--website-font-size) * (-0.36 / 20))`,
        color: "#000000",
        mt: 2,
        paddingBottom: { sm: 0, xs: 0.5 },
      }}
    >
      © Shock’em™ • 2024 | All Rights Reserved
    </Box>
  );
}
