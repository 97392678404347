import React from "react";
import { Card, CardMedia, CardContent, Typography, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ImagePreviewProps {
  imageUrl: string;
  title?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ImagePreviewCard({ imageUrl, title, open, setOpen }: ImagePreviewProps) {
  return (
    <Dialog open={open} maxWidth="xs" sx={{ borderRadius: 3 }}>
      <Card sx={{ position: "relative", border: "3px solid #424242" }}>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
        <CardMedia component="img" image={imageUrl} alt={title} sx={{ objectFit: "cover" }} />

        <CardContent
          sx={{
            position: "absolute",
            bottom: -10,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: 1,
          }}
        >
          <Typography variant="h6" color="white" textAlign="center">
            {title || "Image Preview"}
          </Typography>
        </CardContent>
      </Card>
    </Dialog>
  );
}
