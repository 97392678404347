import { useEffect, useState } from "react";
import { scratchCardApi } from "../apis/ScratchCardApi";
import { Box, Button, Stack, Typography, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { Wheel } from "react-custom-roulette";
import { rewardApi } from "../apis/RewardApi";
import CouponRewardDetails from "../components/reward-claim/CouponRewardDetails";
import { getImageUrlFromReward } from "../utils/reward";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMobile } from "../hooks/useIsMobile";
import orangeBackground from "../assets/images/orangebackground.png";
import orangeBackgroundConfetti from "../assets/images/orangeBackgroundConfetti.png";
import congrats from "../assets/images/congrats.png";
import congratWhite from "../assets/images/congrat-white.png";
import spinWheelLogo from "../assets/images/spinWheelLogo.png";
import "../styles/RedeemCoupon.css";
import { scratchCardUserStatusEnum } from "../types/scratchCardEnum";
import ContactUsText from "../components/common/ContactUsText";
import LoadingDialog from "../components/common/LoadingDialog";

export default function RedeemCoupon() {
  const param = useParams<{ couponCode: string }>();
  const couponCode = param.couponCode;

  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.user.userId);

  const [scratchCardUserDetails, setScratchCardUserDetails] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // State to control LoadingDialog

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spinningWheelRewardData, setSpinningWheelRewardData] = useState<any>(null);
  const navigate = useNavigate();

  // fetch rewards
  useEffect(() => {
    async function fetchRewards() {
      setLoading(true);
      try {
        if (!scratchCardUserDetails?.scratchCardId?.eventId?._id) return;

        let rewardsData = await rewardApi.getRewards({
          eventId: scratchCardUserDetails?.scratchCardId?.eventId?._id,
        });

        let rewardsForGroup = rewardsData.rewards;
        if (!rewardsForGroup || !rewardsForGroup.length) return;
        let spinningWheelRewardData_ = rewardsForGroup.map((reward: any, index: number) => {
          return {
            option: index,
            image: {
              landscape: false,
              uri: getImageUrlFromReward(reward),
            },
            style: { textColor: "black" },
          };
        });

        setPrizeNumber(
          rewardsForGroup.findIndex(
            (reward: any) => reward._id === scratchCardUserDetails?.scratchCardId?.rewardId?._id
          )
        );
        setSpinningWheelRewardData(spinningWheelRewardData_);
      } catch (error: any) {
        console.error(error);
        dispatch(
          openSnackbar({
            message: "Failed to fetch reward: ",
            severity: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }

    if (
      scratchCardUserDetails?.scratchCardId &&
      scratchCardUserDetails?.scratchCardId?.rewardId?._id &&
      scratchCardUserDetails?.scratchCardId?.eventId?._id
    ) {
      fetchRewards();
    }
  }, [scratchCardUserDetails?.scratchCardId]);

  useEffect(() => {
    async function handleApplyCodeSubmit() {
      setLoading(true);
      if (!couponCode || !couponCode.trim()) return;
      try {
        const scratchCardDetails = await scratchCardApi.getScratchCardDetails({ fullCode: couponCode });

        if (!scratchCardDetails || scratchCardDetails.status === "created") {
          dispatch(
            openSnackbar({
              message: "Coupon not activated yet! Please Contact Shockem Team.",
              severity: "error",
            })
          );
          setErrorMessage("Coupon not activated yet! Please Contact Shockem Team.");
          return;
        }

        if (
          scratchCardDetails &&
          scratchCardDetails.ownerCount > 0 &&
          scratchCardDetails.ownerLimit === scratchCardDetails.ownerCount
        ) {
          let userExistsInOwnerList = scratchCardDetails.owners.map((owner: any) => owner.ownerUserId).includes(userId);

          if (!userExistsInOwnerList) {
            dispatch(
              openSnackbar({
                message: "This coupon is already claimed by another User/Users. Please try again with another coupon.",
                severity: "error",
              })
            );
            setErrorMessage("This coupon is already claimed by another user. Please contact Shockem Team.");
            return;
          }
        }

        try {
          await scratchCardApi.redeemScratchCard({
            scratchCardId: scratchCardDetails._id,
            ownerUserId: userId,
          });
        } catch (error: any) {
          console.error(error);
        } finally {
          let { scratchCardUsers } = await scratchCardApi.getPaginatedScratchCardUsers({
            scratchCardIds: scratchCardDetails._id,
            ownerUserIds: [userId],
            populate: true,
          });
          let scratchCardUserDetails_ = scratchCardUsers?.[0];

          setScratchCardUserDetails(scratchCardUserDetails_);
        }
      } catch (error: any) {
        console.error(error);
        dispatch(openSnackbar({ message: "Invalid coupon code: ", severity: "error" }));
      } finally {
        setLoading(false);
      }
    }

    if (couponCode) {
      handleApplyCodeSubmit();
    }
  }, [couponCode]);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      sx={{ mt: "4.5rem", flexDirection: isMobile ? "column" : "row", maxWidth: "100%" }}
    >
      <LoadingDialog open={loading} text="Validating your coupon and preparing the reward wheel… Please wait!" />
      {errorMessage ? (
        <Typography variant="h6" gutterBottom color="error">
          {errorMessage}
        </Typography>
      ) : (
        <Stack sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
            {scratchCardUserDetails &&
              (scratchCardUserDetails?.status === scratchCardUserStatusEnum.RewardAssigned ||
                scratchCardUserDetails?.status === scratchCardUserStatusEnum.Claimed) && (
                <CouponRewardDetails scratchCardUserDetails={scratchCardUserDetails} />
              )}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ boxSizing: "border-box", width: { xs: "100%", sm: "50%" } }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ padding: "2rem", width: { xs: "auto", sm: "68%" } }}
              >
                <Paper
                  sx={{
                    textAlign: "center",
                    backgroundColor: "white",
                    borderRadius: 3,
                    width: "100%",
                    height: "100%",
                    boxShadow: 3,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      backgroundImage: `url(${isMobile ? orangeBackground : orangeBackgroundConfetti})`,
                      backgroundSize: "105% 105%",
                      backgroundPosition: "center",
                      width: "100%",
                      aspectRatio: `1700/500`,
                    }}
                  >
                    {isMobile && <Box component={"img"} src={congratWhite} sx={{ width: "80%", mt: 1 }} />}
                    {!isMobile && (
                      <Box
                        component="img"
                        src={spinWheelLogo}
                        sx={{ position: "absolute", top: "50%" }}
                        alt="Shock'em Rewards"
                        width="8rem"
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      boxSizing: "border-box",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      position: "relative",
                      width: "100%",
                      height: "70%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      paddingBottom: "5%",
                      marginTop: "1rem",
                    }}
                  >
                    <Box>
                      {!isMobile && (
                        <Box
                          component="img"
                          src={congrats}
                          sx={{ width: "20rem", mt: 5 }}
                          alt="Shock'em Rewards"
                          width="50%"
                        />
                      )}
                      <Typography>
                        You have successfully created your account! Spin the wheel to see what you win!
                      </Typography>

                      {isMobile && <Typography sx={{ marginTop: "1rem" }}>Good luck!</Typography>}

                      {!isMobile && (
                        <Typography sx={{ marginTop: "1rem" }}>
                          To spin the reward wheel, click the "spin the wheel" button below.
                        </Typography>
                      )}

                      <Button
                        variant="contained"
                        size="large"
                        sx={{ marginTop: "1rem", backgroundColor: "#2fd484", fontSize: "1.4rem" }}
                        onClick={() => {
                          setMustSpin(true);
                        }}
                        color="primary"
                        disabled={
                          scratchCardUserDetails?.status === scratchCardUserStatusEnum.RewardAssigned ||
                          scratchCardUserDetails?.status === scratchCardUserStatusEnum.Claimed
                            ? true
                            : false
                        }
                      >
                        SPIN THE WHEEL
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: { xs: "100%", sm: "50%" },
                pt: { xs: 0, sm: "2rem" },
                overflow: "hidden",
              }}
            >
              {spinningWheelRewardData &&
                spinningWheelRewardData.length > 0 &&
                prizeNumber >= 0 &&
                prizeNumber < spinningWheelRewardData.length && (
                  <Wheel
                    outerBorderColor={"#f05725"}
                    outerBorderWidth={10}
                    innerBorderColor={"tranparent"}
                    radiusLineColor={"#fff"}
                    radiusLineWidth={1}
                    textColors={["#fff"]}
                    textDistance={60}
                    startingOptionIndex={0}
                    backgroundColors={["#165FA9", "#239b63", "#F7A415", "#3F297E", "#BE1080", "#DC0836"]}
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={spinningWheelRewardData}
                    onStopSpinning={() => {
                      scratchCardApi
                        .updateRewardDisplayedScratchCard({
                          scratchCardId: scratchCardUserDetails?.scratchCardId?._id,
                          ownerUserId: userId,
                        })
                        .then((data: any) => {
                          setScratchCardUserDetails({
                            ...scratchCardUserDetails,
                            status: scratchCardUserStatusEnum.RewardAssigned,
                          });
                        })
                        .catch((error: any) => {
                          console.error(error);
                          dispatch(
                            openSnackbar({
                              message: "Failed to display reward: ",
                              severity: "error",
                            })
                          );
                        });
                    }}
                  />
                )}
            </Box>
            {isMobile && (
              <Box p={2}>
                <ContactUsText />
              </Box>
            )}
          </Box>
          {!isMobile && <ContactUsText />}
        </Stack>
      )}
    </Box>
  );
}
