import { Box, Typography } from "@mui/material";
import ReferFriend from "../components/user/ReferFriend";
import referFriendImage from "../assets/images/refer-friend.png";
import rewardFriendBanner from "../assets/images/reward-friend-banner.png";

export default function ReferUserPage() {
  // const [referralSent, setReferralSent] = useState(false);
  const bannerLogoImage = referFriendImage;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "1rem", sm: "0.5rem" },
      }}
    >
      <Box sx={{ height: { xs: "5rem", sm: "5rem" } }} />

      {/* Banner Section */}
      <Box
        sx={{
          borderRadius: "1rem 1rem 1rem 1rem",
          display: "flex",
          flexDirection: "row", // Use column layout to stack icon and text
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          backgroundImage: `url(${rewardFriendBanner})`,
          backgroundSize: "105% 105%",
          backgroundPosition: "center",
          width: { sm: "70%", xs: "100%" },
          height: { sm: "6rem", xs: "3rem" },
          padding: "0.5rem",
          textAlign: "center", // Centers the text
        }}
      >
        {/* Icon in the center */}
        {/* {bannerLogoImage && (
          <Avatar
            src={bannerLogoImage}
            sx={{
              height: { sm: "150px", xs: "120px" },
              width: { sm: "150px", xs: "120px" },
            }}
          />
        )} */}
        {/* <Typography
          variant="h1"
          fontWeight="bold"
          sx={{
            color: "white",
            fontSize: { xs: "1.4rem", sm: "4rem" }, // Responsive font size
          }}
        >
          REWARD-A-FRIEND
        </Typography> */}
      </Box>

      {/* Subtitle Section */}
      <Box
        sx={{
          mt: 0,
          textAlign: "center",
          width: { sm: "70%", xs: "100%" },
          padding: { xs: "0.5rem", sm: "1rem" },
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "1.1rem", sm: "1.5rem" },
            lineHeight: { xs: "1.2", sm: "1.4" },
          }}
        >
          You have some amazing friends, let's share a reward with them!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 1,
            color: "text.secondary",
            fontSize: { xs: "1rem", sm: "1.0rem" },
            lineHeight: { xs: "1.2", sm: "1.4" },
          }}
        >
          Help us grow the Shock'em Community by sending some of your amazing friends a reward from Shock'em! It's as
          easy as 1, 2, 3. On behalf of the entire team at Shock'em, <strong>THANK YOU!</strong>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 3,
            width: "100%",
          }}
        >
          <ReferFriend />
        </Box>
      </Box>

      {/* Refer Friend Section */}
    </Box>
  );
}
