import React, { useEffect } from "react";
import { Box, Button, TextField, Typography, IconButton, Card, CardContent, CardActions, Dialog } from "@mui/material";

import ShockemPopupBox from "../common/ShockemPopupBox";

import { useNavigate } from "react-router-dom";
import referFriendImage from "../../assets/images/refer-friend.png";
import orangeBackgroundConfetti from "../../assets/images/orangeBackgroundConfetti.png";

import { useIsMobile } from "../../hooks/useIsMobile";

const ReferFriendPopup = ({ onClose, onOpen }: { onClose?: () => void; onOpen?: () => void }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (onOpen) onOpen();
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "1rem",
          overflow: "hidden",
        },
      }}
    >
      <ShockemPopupBox bannerImage={orangeBackgroundConfetti} bannerLogoImage={referFriendImage} onClose={onClose}>
        <Typography fontWeight="bold" sx={{ mt: "1rem", fontSize: isMobile ? "4.5vw" : "1.5vw" }}>
          You have some amazing friends, let's share a reward with them too!
        </Typography>

        <Typography sx={{ mt: "1rem", textAlign: "justify", fontSize: isMobile ? "3vw" : "1vw" }}>
          There are no limits to what can be accomplished through collaboration. That sentiment especially extends to
          the community we hope to grow. We are proudly indie and promise passionate stories, hand-illustrated art, and
          we want our readers to be a part of the story. We know we cannot succeed without your help!
        </Typography>
        <Typography
          fontWeight="bold"
          sx={{ mt: "1rem", lineHeight: 1.2, textAlign: "justify", fontSize: isMobile ? "4.5vw" : "1.5vw" }}
        >
          It's as easy as 1, 2, 3!
        </Typography>

        <Button
          variant="contained"
          sx={{
            fontSize: isMobile ? "3vw" : "1vw",
            mt: "1rem",
            backgroundColor: "#24c477", // Set green background color
            color: "white", // Set text color to white
            padding: "0.4rem 1.2rem",
            "&:hover": {
              backgroundColor: "#1f9c61", // Optional: Darker shade for hover effect
            },
          }}
          onClick={() => {
            navigate("/refer-a-friend");
          }}
        >
          REWARD MY FRIENDS!
        </Button>
        {onClose && (
          <Typography
            sx={{ marginTop: "1rem", fontSize: isMobile ? "3vw" : "1vw" }}
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            <Box
              component="a"
              sx={{ textDecoration: "underline", color: "primary.main", cursor: "pointer" }}
              onClick={() => navigate("/user")}
            >
              I don't want to reward my friends
            </Box>
          </Typography>
        )}
      </ShockemPopupBox>
    </Dialog>
  );
};

export default ReferFriendPopup;
