import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import WelcomeSection from "../components/common/WelcomeSection";
import Header from "../components/common/Header";
import { validateEmail, validatePassword, validateUserName } from "../utils/validation";
import PasswordRequirement from "../components/signup/PasswordRequirement";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { signupApi } from "../apis/SignupApi";
import EmailSentMessage from "../components/signup/EmailVerification";
import { loginApi } from "../apis/LoginApi";
import { Link, useNavigate } from "react-router-dom";
import { setUserId } from "../redux/slices/user";
import Cookies from "js-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { scratchCardApi } from "../apis/ScratchCardApi";
import WinnersList from "../components/common/WinnersList";
import { scratchCardUserStatusEnum } from "../types/scratchCardEnum";
import { UserStepEnum, UserTypeEnum } from "../types/userStageEnum";
import { getNextStep } from "../utils/user";
import ContactUs from "./ContactUs";
import ContactUsText from "../components/common/ContactUsText";
interface SignupProps {
  showLoginOption?: boolean;
  skipVerification?: boolean;
  emailReceived?: string;
  couponCode?: string;
}
export default function Signup({ showLoginOption = true, skipVerification, emailReceived, couponCode }: SignupProps) {
  const navigate = useNavigate();
  const [userId, setLocalUserId] = useState("");
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recentRewards, setRecentRewards] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showVerifyEmailStep, setShowVerifyEmailStep] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const passwordRequirements = validatePassword(password, confirmPassword).passwordRequirements;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (emailReceived) {
      setEmail(emailReceived);
    }
  }, [emailReceived]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    try {
      if (!validateEmail(email)) throw new Error("Please enter a valid email address.");
      if (!validatePassword(password, confirmPassword).isPasswordValid)
        throw new Error("Please enter a valid password.");
      if (!validateUserName(userName)) throw new Error("Please enter a valid username.");
      if (skipVerification) {
        let stepProgress;
        if (couponCode) {
          const scratchCardDetails = await scratchCardApi.getScratchCardDetails({
            fullCode: couponCode,
          });
          if (!scratchCardDetails) throw new Error("No coupon code found");
          stepProgress = {
            type: UserTypeEnum.ScratchCard,
            activeStep: getNextStep(UserStepEnum.CreateAccount, UserTypeEnum.ScratchCard).nextStep,
            stepTimestamp: new Date().toISOString(),
            fullCode: couponCode,
          };
        }
        const res = await signupApi.signUpUser(email, password, userName, skipVerification, stepProgress);
        if (!res?.success) throw new Error("Error in signing up");
        const userId = res?.user?._id;
        const jwtToken = res?.user?.jwtToken;
        if (!userId) throw new Error("No user found");
        if (!jwtToken) throw new Error("Authorization failed");
        setLocalUserId(res.user._id);
        if (rememberMe) {
          Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
        }
        dispatch(setUserId({ userId, jwtToken }));
        navigate(`/redeem-scratch-card/${couponCode}`);
        dispatch(openSnackbar({ message: "Email verified", severity: "success" }));
      } else {
        const res = await signupApi.signUpUser(email, password, userName);
        if (!res?.success) throw new Error("Error in signing up");
        setLocalUserId(res.user._id);
        await loginApi.sendEmailForSignupVerification({ userId: res.user._id });
        if (rememberMe) {
          Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
        }
        setShowVerifyEmailStep(true);
      }
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "Sign up failed: " + error.message, severity: "error" }));
    }
  }

  useEffect(() => {
    async function fetchRecentRewards() {
      const { scratchCardUsers, total } = await scratchCardApi.getPaginatedScratchCardUsers({
        page: 0,
        limit: 5,
        status: [scratchCardUserStatusEnum.Claimed, scratchCardUserStatusEnum.RewardAssigned],
        sortKey: "updatedAt",
        sortDirection: "-1",
        populate: true,
      });

      setRecentRewards(scratchCardUsers);
    }
    if (!showLoginOption) fetchRecentRewards();
  }, [showLoginOption]);

  return (
    <Box display={"flex"}>
      <Stack sx={{ minHeight: "100vh", width: "100%", flexDirection: { xs: "column-reverse", sm: "row" } }}>
        <Box
          component={Paper}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{
              my: { sm: 10, xs: 2 },
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: couponCode ? "flex-start" : "center",
              justifyContent: "flex-start",
            }}
          >
            {!couponCode && (
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
            )}
            <Typography fontSize={"1.5rem"} fontWeight={"bold"} sx={{ m: "1rem 0" }}>
              {couponCode ? "You're almost there!" : "Sign Up"}
            </Typography>
            {couponCode && (
              <Typography fontSize={"0.8rem"} sx={{ mb: "1rem" }}>
                To help us provide you with access to your reward, please create an account below. We only ask for a
                username, email, and password. It’s that easy!
              </Typography>
            )}

            {showVerifyEmailStep && userId && email ? (
              <EmailSentMessage email={email} userId={userId} />
            ) : (
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  helperText="Username must be 2 to 16 characters long and can only contain letters (A-Z, a-z) and numbers (0-9)."
                  value={userName}
                  autoFocus
                  disabled={showVerifyEmailStep}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  disabled={!!emailReceived || showVerifyEmailStep}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  id="password"
                  value={password}
                  disabled={showVerifyEmailStep}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                  value={confirmPassword}
                  id="confirmPassword"
                  disabled={showVerifyEmailStep}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      onChange={() => {
                        setRememberMe(!rememberMe);
                      }}
                      checked={rememberMe}
                    />
                  }
                  label="Remember me"
                />
                {!showVerifyEmailStep && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2">Password must include:</Typography>
                    <PasswordRequirement label={"At least 8 characters"} valid={passwordRequirements.length} />
                    {/* <PasswordRequirement
                      label={"At least one special character"}
                      valid={passwordRequirements.specialChar}
                    />
                    <PasswordRequirement
                      label={"Both upper and lower case letters"}
                      valid={passwordRequirements.upperLower}
                    /> */}
                    <PasswordRequirement label={"At least one number"} valid={passwordRequirements.number} />
                    <PasswordRequirement
                      label={"Passwords match"}
                      valid={password === confirmPassword && passwordRequirements.length}
                    />
                  </Box>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={!validatePassword(password, confirmPassword).isPasswordValid}
                >
                  Sign Up
                </Button>
                Already have an account? <Link to="/login">Sign In</Link>
              </Box>
            )}

            <ContactUsText styleObj={{ p: 0, pt: 2 }} />
          </Box>
        </Box>

        <Box
          sx={{
            background: "linear-gradient(to bottom, #f0a725, #f05725)",
            display: "flex",
            justifyContent: "center",
            alignItems: couponCode ? "flex-start" : "center",
            color: "white",
            flexGrow: 1,
            width: { xs: "100%", sm: "50%" },
            height: { xs: "auto", sm: "auto" },
            paddingTop: { xs: 12, sm: 2 },
            pb: "4rem",
          }}
        >
          {showLoginOption ? (
            <WelcomeSection title="Welcome!" page="signup" />
          ) : (
            <WinnersList recentRewards={recentRewards} />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
