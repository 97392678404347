import { Stack } from "@mui/material";
import Copyright from "./Copyright";
import { Facebook, Instagram, X, YouTube } from "@mui/icons-material";
import { FaDiscord, FaTiktok } from "react-icons/fa";

export default function Footer({ showFooterSocialIcons = true }: { showFooterSocialIcons?: boolean }) {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"white"}
      maxWidth={"100%"}
      boxSizing={"border-box"}
      sx={{
        minHeight: { xs: "10rem", sm: "5rem" },
        width: "100%",
        boxShadow: 5,
        gap: { xs: 2, sm: 1 },
        p: { xs: 5, sm: 3 },
      }}
      position={"relative"}
      bottom={0}
    >
      {showFooterSocialIcons && (
        <Stack direction={"row"} sx={{ fontSize: { xs: "2rem", sm: "1.3rem" } }} gap={2}>
          <FaDiscord
            fontSize={"inherit"}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://discord.com/invite/shockemcomics", "_blank")}
          />
          <Instagram
            fontSize={"inherit"}
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.instagram.com/shockemcomics/", "_blank")}
          />
          <X
            fontSize={"inherit"}
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://x.com/shockem_comics?lang=en", "_blank")}
          />
          <FaTiktok
            fontSize={"inherit"}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.tiktok.com/@shockemcomics", "_blank")}
          />
          <Facebook
            fontSize={"inherit"}
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.facebook.com/people/Shockem-Comics/61560347762061/", "_blank")}
          />
          <YouTube
            fontSize={"inherit"}
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.youtube.com/@shockemcomics", "_blank")}
          />
        </Stack>
      )}
      <Copyright />
    </Stack>
  );
}
