import React from "react";
import { Card, CardContent, Skeleton, Box } from "@mui/material";
import lightingBolt from "../../assets/images/lighting-bolt.png";

export default function SkeletonCard() {
  return (
    <Card sx={{ maxWidth: 345, borderRadius: 2, height: "100%", position: "relative" }}>
      <Box
        component="img"
        src={lightingBolt}
        alt="Lightning Bolt"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          width: 40,
          height: 40,
          zIndex: 1,
        }}
      />

      <Skeleton variant="rectangular" width="100%" height={208} sx={{ borderRadius: 1 }} />
      <CardContent sx={{ padding: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        <Skeleton variant="text" width="80%" height={24} />
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
          <Skeleton variant="text" width="40%" height={24} />
          <Skeleton variant="text" width="30%" height={24} />
        </Box>
      </CardContent>
    </Card>
  );
}
