import { Box } from "@mui/material";
import Header from "../components/common/Header";

export default function Item() {
  // const param = useParams<{ itemId: string; collectionId: string }>();
  // const collectionId = param.collectionId;
  // const itemId = param.itemId;

  return <Box></Box>;
}
