import { Box, Skeleton, Tabs, Tab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SkeletonCard from "../common/SkeletonCard";
import lightingBolt from "../../assets/images/lighting-bolt.png";

export default function UserSkeleton() {
  return (
    <Box sx={{ height: "auto", width: "100%", overflowX: "hidden" }}>
      {/* Placeholder for the top spacing */}
      <Box sx={{ height: { xs: "4.5rem", sm: "4.5rem" } }} />

      {/* Skeleton for the cover image */}
      <Box position={"relative"}>
        <Skeleton variant="rectangular" height="15rem" width="100%" sx={{ mb: 2 }} animation="wave" />
        <Box
          component="img"
          src={lightingBolt}
          alt="Lightning Bolt"
          sx={{
            position: "absolute",
            top: 10,
            right: 16,
            width: 40,
            height: 40,
            zIndex: 1,
          }}
        />
        <Box sx={{ position: "absolute", top: { xs: 200, sm: 230 }, left: 80, transform: "translateX(-50%)" }}>
          <Skeleton variant="circular" width="7rem" height="7rem" />
        </Box>
      </Box>

      <Box
        display="flex"
        gap={2}
        sx={{
          width: "94%",
          position: "relative",
          flexDirection: { xs: "column", sm: "column" },
          justifyContent: "space-between",
          alignItems: "center",
          mx: "auto",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            mt: { xs: 6, sm: 0 },
            width: { xs: "100%", sm: "100%" },
            gap: 2,
          }}
        >
          <Skeleton variant="rounded" height="3rem" width="30%" animation="wave" sx={{ ml: { xs: 2, sm: 16 } }} />

          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {[1, 2, 3].map((_, i) => (
              <Skeleton key={i} variant="circular" height="3rem" width="3rem" animation="wave" />
            ))}
          </Box>
        </Box>
        <Skeleton variant="rounded" height="5rem" width="93%" animation="wave" sx={{ ml: { xs: 0, sm: 16 } }} />
      </Box>

      <Box sx={{ borderBottom: "1px solid grey", borderColor: "divider", mb: 2 }}>
        <Tabs value={false}>
          {[1, 2, 3].map((i) => (
            <Tab key={i} label={<Skeleton variant="text" width="5rem" />} disabled />
          ))}
        </Tabs>
      </Box>

      <Grid container spacing={2}>
        {Array.from({ length: 20 }).map((_, index) => (
          <Grid key={index} size={{ xs: 6, sm: 2 }}>
            <SkeletonCard />
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          display: "flex",
          gap: 2,
        }}
      >
        <Skeleton variant="circular" width="3rem" height="3rem" />
      </Box>
    </Box>
  );
}
