import { getItemPaginationProps } from "../types/item";
import BaseAPI from "./BaseApi";

class ItemApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/item" });
  }

  async getItems({ issueId, ownerId, limit, page, project }: getItemPaginationProps) {
    try {
      const result = await this.axios.get("/", {
        params: { issueId, ownerId, limit, page, project },
      });
      if (!result.data || !result.data.items) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@ItemApi.getItems:", { response: response }, { error: error.message });
      throw new Error(`@ItemApi.getItems: ${response}`);
    }
  }
}

export const itemApi = new ItemApi();
