import { useEffect } from "react";
import { loginApi } from "../apis/LoginApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { setUserId } from "../redux/slices/user";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    if (token) {
      loginApi
        .verifyEmailApi({ token })
        .then((res) => {
          if (res.success) {
            const userId = res?.user?._id;
            const jwtToken = res?.user?.jwtToken;
            if (!userId) throw new Error("No user found");
            if (!jwtToken) throw new Error("Authorization failed");
            dispatch(setUserId({ userId, jwtToken }));
            navigate("/user");
            dispatch(openSnackbar({ message: "Email verified", severity: "success" }));
          } else {
            navigate("/signup");
            dispatch(openSnackbar({ message: "Email verification failed", severity: "error" }));
          }
        })
        .catch((err) => {
          console.error(err?.message);
          dispatch(openSnackbar({ message: "Email verification failed", severity: "error" }));
          navigate("/signup");
          console.error(err);
        });
    }
  }, [token, dispatch, navigate]);

  return null;
}
