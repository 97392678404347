import { Avatar, Box } from "@mui/material";
import { getImageUrlFromProfileImageField } from "../../utils/user";
import React from "react";

export default function ProfilePicture({ userDetails }: { userDetails: any }) {
  return (
    <React.Fragment>
      <Box
        boxShadow={3}
        component={"img"}
        src={getImageUrlFromProfileImageField(userDetails?.profileImage)}
        alt="user-profile-picture"
        sx={{
          position: "absolute",
          top: "-100%",
          width: "100%",
          aspectRatio: "1/1",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        border={1}
      />
    </React.Fragment>
  );
}
