import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useCallback, useEffect, useState } from "react";
import { scratchCardApi } from "../../../apis/ScratchCardApi";
import RewardCard from "./RewardCard";
import { userSubscriptionApi } from "../../../apis/UserSubscription";
import RipPackCard from "../rip-packs/RipPackCard";
import { ripPackApi } from "../../../apis/RipPack";
import SkeletonCard from "../../common/SkeletonCard";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/slices/snackbar";
import { scratchCardUserStatusEnum } from "../../../types/scratchCardEnum";

interface RewardGridProps {
  userId?: string;
  userScratchCards: any[];
  triggerRefresh?: any;
}

export default function RewardGrid({ userScratchCards, userId, triggerRefresh = () => {} }: RewardGridProps) {
  const dispatch = useDispatch();

  const [ripPacksMap, setRipPacksMap] = useState<any>();
  const [loading, setLoading] = useState(false);
  async function createUserSubscription(userId: string, subscriptionId: string) {
    try {
      if (!userId || !subscriptionId) throw new Error("Invalid User or Subscription");
      const result = await userSubscriptionApi.createUserSubscription({ userId, subscriptionId });
      if (!result) throw new Error("No Created User Subscription");
      return result;
    } catch (error: any) {
      console.error("Error in createUserSubscription:", error);
    }
  }

  async function updateClaimReward(scratchCardId: string, userId: string) {
    try {
      if (!scratchCardId) throw new Error("Failed to claim reward");
      const result = await scratchCardApi.claimScratchCard({ scratchCardId, userId });
      if (!result) throw new Error("Error in claiming reward");
      return result;
    } catch (error: any) {
      console.error("Error in updateClaimReward:", error);
    }
  }

  async function handleClaim({
    userId,
    subscriptionId,
    ripPackId,
    scratchCardId,
  }: {
    userId: string;
    subscriptionId: string | null;
    ripPackId: string | null;
    scratchCardId: string;
  }) {
    try {
      if (!userId || (!subscriptionId && !ripPackId) || !scratchCardId)
        throw new Error("Invalid User or Reward or ScratchCard");
      const [updateClaim] = await Promise.all([updateClaimReward(scratchCardId, userId)]);
      if (!updateClaim) throw new Error("Error in claiming reward");

      let userReward = null;
      if (subscriptionId) userReward = await createUserSubscription(userId, subscriptionId);

      //todo rip pack claim
      // if (ripPackId) userReward = await function(userId, ripPackId);

      if (!updateClaim || !userReward) throw new Error("Error in claiming reward");

      dispatch(openSnackbar({ message: "Reward Claimed Successfully!", severity: "success" }));
      triggerRefresh();
    } catch (error: any) {
      console.error("Error in handleClaim:", error);
      dispatch(openSnackbar({ message: "Reward Claim failed", severity: "error" }));
    }
  }

  useEffect(() => {
    async function fetchRipPackDetails() {
      const ripPackIds = userScratchCards?.map((scratchCard: any) => scratchCard?.rewardDetails?.ripPackId);
      try {
        setLoading(true);
        if (!ripPackIds) return;
        const result = await ripPackApi.getRipPackDetails({ ripPackIds });
        if (!result) throw new Error("No RipPack Details");
        const ripPackMap = result?.ripPacks.reduce((acc: any, pack: any) => {
          const { _id, ...restDetails } = pack?.details;
          const { ...eventDetails } = pack?.stats?.events?.find((event: any) => event.userId === userId) || {};
          acc[_id] = { ...restDetails, ...eventDetails };
          return acc;
        }, {});

        setRipPacksMap(ripPackMap);
      } catch (error: any) {
        console.error("Error in fetchRipPackDetails:", error);
      } finally {
        setLoading(false);
      }
    }
    if (!userScratchCards) return;
    fetchRipPackDetails();
  }, [userScratchCards, userId]);

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <Grid container spacing={2}>
        {!loading ? (
          <>
            {" "}
            {userScratchCards && userScratchCards.length > 0 ? (
              <React.Fragment>
                {userScratchCards.map((userScratchCard: any, index: number) => (
                  <Grid key={index} size={{ xs: 6, sm: 4, md: 4, lg: 2, xl: 2 }}>
                    {userScratchCard?.rewardDetails &&
                    userScratchCard?.rewardDetails?.type === "RIP Pack" &&
                    userScratchCard?.status !== scratchCardUserStatusEnum.Redeemed ? (
                      <RipPackCard
                        userScratchCardDetails={userScratchCard}
                        ripPackDetails={ripPacksMap?.[userScratchCard?.rewardDetails?.ripPackId]}
                      />
                    ) : (
                      <RewardCard
                        scratchCardDetails={userScratchCard?.scratchCardId}
                        reward={userScratchCard?.scratchCardId?.rewardId}
                        event={userScratchCard?.scratchCardId?.eventId}
                        userScratchCardStatus={userScratchCard?.status}
                        onClaim={(userId: string, subscriptionId: string | null, ripPackId: string | null) => {
                          handleClaim({
                            userId,
                            subscriptionId,
                            ripPackId,
                            scratchCardId: userScratchCard?.scratchCardId?._id,
                          });
                        }}
                      />
                    )}
                  </Grid>
                ))}
              </React.Fragment>
            ) : (
              <Box p={2}>
                <Typography>No Rewards to Claim</Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {" "}
            {Array.from({ length: 20 }).map((_, index) => (
              <Grid key={index} size={{ xs: 6, sm: 2 }}>
                <SkeletonCard />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
}
