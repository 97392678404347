import ImageNotFoundImg from "../assets/images/image-not-found.png";
import UserDefaultCover from "../assets/images/user-default-cover.png";
import UserDefaultProfileImage from "../assets/images/user-default-image.png";
import { UserStepEnum, UserTypeEnum } from "../types/userStageEnum";

export let shockemUserDefaultValues = {
  interests: ["Shock'em Comics", "Legends of the Cosmos", "One Million Comics Giveaway", "Comic Cons"],
  description:
    "I love Shock'em Comics! As dedicated indie creators, they passionately deliver captivating stories and stunning hand-illustrated art, all while championing geek culture. To fully experience their creativity and enthusiasm, connect with the Shock'em Comics team and join the Shock'em Army on social media. Stay in the loop with all the latest news and updates—you won't want to miss out!",
  externalLinks: {
    instagram: "https://www.instagram.com/shockemcomics/",
    twitter: "https://x.com/shockem_comics?lang=en",
    email: "",
    website: "https://shockemcomics.com/",
    discord: "https://discord.com/invite/shockemcomics",
    youtube: "https://www.youtube.com/@shockemcomics",
    tiktok: "https://www.tiktok.com/@shockemcomics",
    facebook: "https://www.facebook.com/people/Shockem-Comics/61560347762061/",
  },
};

export function getImageUrlFromProfileImageField(userIdWithVersion: string) {
  const s3Path = `users/profile-images/${userIdWithVersion}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;
  if (!userIdWithVersion || !s3Path || !cloudfrontPrefix) {
    return UserDefaultProfileImage;
  }
  return `${cloudfrontPrefix}/${s3Path}`;
}

export function getDescriptionFromUser(user: any) {
  if (user?.description) {
    return user.description;
  }
  return shockemUserDefaultValues.description;
}

export function getInterestsFromUser(user: any) {
  return user?.interests && user?.interests?.length > 0 ? user?.interests : shockemUserDefaultValues.interests;
}

export function getExternalLinksFromUser(user: any, setDefault = true) {
  if (user?.externalLinks) {
    return user.externalLinks;
  }
  return setDefault ? shockemUserDefaultValues.externalLinks : {};
}

export function getImageUrlFromCoverImageField(userIdWithVersion: string) {
  const s3Path = `users/cover-images/${userIdWithVersion}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;
  if (!userIdWithVersion || !s3Path || !cloudfrontPrefix) {
    console.error("@getImageUrlFromUserId: Invalid s3Path or cloudfrontPrefix", { s3Path, cloudfrontPrefix });
    return UserDefaultCover;
  }
  return `${cloudfrontPrefix}/${s3Path}`;
}

export function getRankSuffix(rank: number): string {
  if (rank === 11 || rank === 12 || rank === 13) {
    return `${rank}th`;
  }

  const lastDigit = rank % 10;
  switch (lastDigit) {
    case 1:
      return `${rank}st`;
    case 2:
      return `${rank}nd`;
    case 3:
      return `${rank}rd`;
    default:
      return `${rank}th`;
  }
}

export const getStepsLabels = () => ({
  basic: [UserStepEnum.CreateAccount, UserStepEnum.UpdateUserInfo, UserStepEnum.ReferAFriend, UserStepEnum.Complete],
  scratchCard: [
    UserStepEnum.CreateAccount,
    UserStepEnum.RedeemReward,
    UserStepEnum.UpdateUserInfo,
    UserStepEnum.ReferAFriend,
    UserStepEnum.Complete,
  ],
  // promoCode: [
  //   UserStepEnum.CreateAccount,
  //   UserStepEnum.RedeemReward,
  //   UserStepEnum.UpdateUserInfo,
  //   UserStepEnum.ReferAFriend,
  //   UserStepEnum.Complete,
  // ],
});
// export const getStepsLabels = () => ({
//   basic: ["Account Created", "Update User Info", "Refer a Friend", "Complete"],
//   scratchCard: ["Account Created", "Reward Redeemed", "Profile Details Updated", "Refer a Friend Program", "Complete"],
// });

export function formatString(str: string): string {
  return str
    .replace(/[_-]+/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function getNextStep(currentStep: string, stepType: UserTypeEnum) {
  const labels = getStepsLabels()[stepType];
  const currentIndex = labels.findIndex((label) => label.toLowerCase() === currentStep.toLowerCase());

  if (currentIndex === -1) {
    return { nextStep: null, currentIndex: -1 };
  }

  const nextStepLabel = currentIndex < labels.length - 1 ? labels[currentIndex + 1] : null;
  return { nextStep: nextStepLabel?.toLowerCase(), currentIndex };
}

export function isFinalStep(currentStep: UserStepEnum, stepType: UserTypeEnum) {
  const labels = getStepsLabels()[stepType];
  const currentIndex = labels.findIndex((label) => label.toLowerCase() === currentStep.toLowerCase());

  return currentIndex !== -1 && currentIndex === labels.length - 1;
}
