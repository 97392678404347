import { Chip, InputAdornment, Stack, TextField } from "@mui/material";
import { getInterestsFromUser } from "../../utils/user";

export default function UserInterests({ userDetails }: { userDetails: any }) {
  return (
    <TextField
      id="outlined-basic"
      label="INTERESTS"
      variant="outlined"
      autoFocus
      disabled
      sx={{
        width: "100%",
        mt: { xs: "0.5rem", sm: "0.5rem" },
        "& .MuiInputBase-root": {
          height: "auto", // Allow the input to grow
          overflow: "visible", // Make overflow visible
        },
        "& .MuiInputBase-input": {
          height: "auto", // Allow the input to grow
          overflow: "visible", // Make overflow visible
          width: "0",
        },
        "& .MuiInputAdornment-root": {
          maxHeight: "none",
          width: "100%",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              borderRadius={2}
              gap={1}
              width={"100%"}
              flexWrap={"wrap"}
              sx={{
                marginTop: "0.4rem",
                flexWrap: "wrap",
                width: "100%", // Ensure the stack takes full width
                p: "8px 0", // Padding top and bottom inside the input
              }}
            >
              {getInterestsFromUser(userDetails).map((interest: string, index: number) => (
                <Chip
                  sx={{ backgroundImage: "linear-gradient(to right, #ffcc00 5%, #ff6600 100%)" }}
                  key={index}
                  label={interest}
                />
              ))}
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
}
