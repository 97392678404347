import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, InputBase, IconButton, Stack } from "@mui/material";
import { collectionApi } from "../apis/CollectionApi";
import Category from "../components/leaderboard/Category";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";

export default function CollectionLeaderBoard({ userId }: { userId?: string }) {
  const dispatch = useDispatch();
  const [collectionLeaderBoard, setCollectionLeaderBoard] = useState([]);
  const [filteredCollectionLeaderBoard, setFilteredCollectionLeaderBoard] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let filter = {};
        if (userId)
          filter = {
            ownerId: userId,
          };

        const result = await collectionApi.getCollectionLeaderBoard(filter);
        setCollectionLeaderBoard(result);
        setFilteredCollectionLeaderBoard(result);
        dispatch(openSnackbar({ message: "Leaderboard refreshed", status: "success" }));
      } catch (error: any) {
        dispatch(openSnackbar({ message: "Failed to get leaderboard", status: "error" }));
        console.error("Error in CollectionLeaderBoard.getCollectionLeaderBoard: " + error.message);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    let filteredCollectionLeaderBoard_ = (collectionLeaderBoard || []).filter(
      (collectionDetailsWithItemGroups: any) => {
        return (collectionDetailsWithItemGroups?.collectionDetails?.title || "")
          .toLowerCase()
          .includes(searchString.toLowerCase());
      }
    );
    setFilteredCollectionLeaderBoard(filteredCollectionLeaderBoard_);
  }, [searchString, collectionLeaderBoard]);

  return (
    <React.Fragment>
      {!userId && <Box sx={{ height: { xs: "5rem", sm: "4.5rem" } }} />}
      {!userId && (
        <Stack
          sx={{
            marginLeft: 2,
            marginRight: 2,
            mt: 2,
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "start", sm: "center" },
          }}
        >
          <Stack direction={"row"} sx={{ alignItems: "center", gap: 2 }}>
            <LeaderboardIcon sx={{ fontSize: "3rem" }} />
            <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>Leaderboard</Typography>
          </Stack>
          <Box
            sx={{
              mt: 1,
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "100%", sm: "30rem" },
              gap: 0.5,
            }}
          >
            <Paper
              component="form"
              sx={{ p: "2 4", display: "flex", alignItems: "center", borderRadius: 2, flexGrow: 1 }}
            >
              <IconButton type="button" sx={{ p: "10" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search LeaderBoard"
                inputProps={{ "aria-label": "search leader board" }}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Paper>
            <IconButton type="button" sx={{ padding: "0", height: "100%" }} aria-label="search">
              <TuneIcon />
            </IconButton>
          </Box>
        </Stack>
      )}

      {(filteredCollectionLeaderBoard || []).map((collectionDetailsWithItemGroups: any, index: number) => (
        <Category key={index} collectionDetailsWithItemGroups={collectionDetailsWithItemGroups || {}} />
      ))}
    </React.Fragment>
  );
}
