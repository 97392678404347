import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { rewardApi } from "../../apis/RewardApi";
import Confetti from "react-confetti";

import useWindowSize from "react-use/lib/useWindowSize";
import { getImageUrlFromReward, getNameFromReward } from "../../utils/reward";
import { useNavigate } from "react-router-dom";
import ReferFriendPopup from "./ReferFriendPopup";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ChevronLeft } from "@mui/icons-material";

export default function CouponRewardDetails({ scratchCardUserDetails }: { scratchCardUserDetails?: any }) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rewardDetails, setRewardDetails] = useState<any>();
  const [showReferFriend, setShowReferFriend] = useState(false);
  const [showReferDialog, setShowReferDialog] = useState(false);

  useEffect(() => {
    if (scratchCardUserDetails) {
      rewardApi
        .getRewardDetails({ rewardId: scratchCardUserDetails?.scratchCardId?.rewardId?._id })
        .then((rewardDetails) => {
          setRewardDetails(rewardDetails);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            openSnackbar({
              message: "Error in getting reward details: ",
              severity: "error",
            })
          );
        });
    }
  }, [scratchCardUserDetails]);

  const { width, height } = useWindowSize();
  return (
    <>
      {/* <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1000000000,
        }}
      >
        <Confetti2
          x={0.5}
          y={0.1}
          particleCount={50}
          deg={270}
          shapeSize={8}
          spreadDeg={45}
          effectInterval={2000}
          effectCount={3}
          colors={["#ff577f", "#ff884b", "#ffd384", "#fff9b0", "#3498db"]}
        />
      </div> */}
      {showReferFriend && <ReferFriendPopup onClose={() => setShowReferFriend(false)} />}
      <Card
        elevation={3}
        sx={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "3rem",
          width: !isMobile ? "40%" : "80%",
          zIndex: 1200,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardContent sx={{ textAlign: "center" }}>
          <Confetti width={width} height={height} numberOfPieces={500} friction={0.99} />
          {/* Reward Name */}
          <Typography gutterBottom variant="h4" sx={{ fontWeight: 600, fontFamily: "Poppins, sans-serif" }}>
            {"Congratulations!! You won a " + rewardDetails?.type}
          </Typography>
        </CardContent>
        {/* Reward Image */}
        <CardMedia
          component="img"
          alt={getNameFromReward(rewardDetails)}
          image={getImageUrlFromReward(rewardDetails)} // Ensure rewardDetails includes imageUrl
          title={getNameFromReward(rewardDetails)}
          sx={{
            width: "100%",
            height: 200,
            objectFit: "contain",
            marginBottom: "1rem",
          }}
        />

        <CardContent sx={{ textAlign: "center" }}>
          {/* Reward Name */}
          <Typography gutterBottom variant="h4" sx={{ fontWeight: 600, fontFamily: "Poppins, sans-serif" }}>
            {getNameFromReward(rewardDetails)}
          </Typography>

          {/* Coupon Code */}
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "text.secondary",
            }}
          >
            Reward Code: {scratchCardUserDetails?.scratchCardId?.fullCode || "XXXX-XXXX"}
          </Typography>

          {/* Link to Marketplace */}
          {/* <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Go to Shockem Marketplace to see all your rewards
          </Typography> */}
        </CardContent>

        <Button
          size="large"
          variant="contained"
          sx={{
            color: "#fff",
            background: "green",
            borderRadius: "10px",
            padding: "0.5rem 1rem",
            width: "70%",
            mt: 2,
          }}
          onClick={() => {
            setShowReferFriend(true);
          }}
        >
          Continue
        </Button>
      </Card>
    </>
  );
}
