import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import { loginApi } from "../../apis/LoginApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";

interface OtpVerificationProps {
  isOtpVerified: boolean;
  setIsOtpVerified: any;
  email: string;
}

export default function OtpVerification({ email, isOtpVerified, setIsOtpVerified }: OtpVerificationProps) {
  const [verificationCode, setVerificationCode] = useState("");
  const dispatch = useDispatch();

  async function handleVerification(event: any) {
    event.preventDefault();
    try {
      if (!verificationCode) throw new Error("Please enter verification code");
      const res: any = await loginApi.verifyOtp(email, verificationCode);
      if (!res?.success) throw new Error("Invalid verification code");
      setIsOtpVerified(true);
      setVerificationCode("");
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "Verification failed: " + error.message, severity: "error" }));
    }
  }

  async function handleResendCode(event: any) {
    event.preventDefault();
    try {
      const res: any = await loginApi.generateOtpAndSendEmail({ email });
      if (!res?.success) throw new Error("Error in sending email");
      dispatch(openSnackbar({ message: "Otp Resent successfully", severity: "success" }));
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "Resend failed! Please try again later", severity: "error" }));
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      {!isOtpVerified ? (
        <React.Fragment>
          <Typography variant="body2" color="text.secondary">
            To verify your email address, we have sent a verification code to the email address provided. The code
            expires in 30 minutes.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="verificationCode"
            label="Verification Code"
            type="text"
            id="verificationCode"
            onChange={(e) => setVerificationCode(e.target.value?.toUpperCase())}
          />
          <Button variant="contained" sx={{ mt: 1 }} onClick={handleVerification}>
            Verify Code
          </Button>
          <Button variant="text" sx={{ mt: 1 }} onClick={handleResendCode}>
            Resend Code
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="body2" color="text.secondary">
            Verification Successful <CheckCircleIcon sx={{ color: green[500], fontSize: "1rem" }} />
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
}
