import * as React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { formatString } from "../../utils/user";
import { linearGradients } from "../../configs/muiTheme";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: linearGradients.secondary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: linearGradients.secondary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundImage: "linear-gradient(to right, #d0d0d0, #b0b0b0)",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; error?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "black",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(to bottom, #f0a725, #f05725)",
  }),
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(to bottom, #f0a725, #f05725)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.error && {
    backgroundColor: "#f44336",
  }),
}));

function ColorlibStepIcon(props: StepIconProps & { icon: React.ReactNode }) {
  const { active, completed, error, className, icon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
      {completed ? <Check /> : error ? <ErrorIcon /> : icon}
    </ColorlibStepIconRoot>
  );
}

// Custom Stepper component
interface CustomStepperProps {
  steps: any[];
  activeStep: number;
}

export default function CustomStepper({ steps, activeStep }: CustomStepperProps) {
  const dispatch = useDispatch();
  async function handleStepClick(index: number) {
    if (steps[index]) {
      if (index === activeStep) {
        if (steps[index].action) await steps[index].action();
        else
          dispatch(
            openSnackbar({
              message: "No action assigned for this active step",
              severity: "warning",
            })
          );
      } else if (index < activeStep)
        dispatch(
          openSnackbar({
            message: "This step is already completed",
            severity: "info",
          })
        );
      else
        dispatch(
          openSnackbar({
            message: "Please complete the previous step first",
            severity: "warning",
          })
        );
    } else
      dispatch(
        openSnackbar({
          message: "This step is not valid",
          severity: "error",
        })
      );
  }
  return (
    <Box sx={{ width: "100%", marginBottom: 3, marginTop: 4 }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map(({ label, icon, error }, index) => (
          <Step
            key={label}
            onClick={() => {
              handleStepClick(index);
            }}
            sx={{ cursor: "pointer" }}
          >
            <StepLabel
              StepIconComponent={(props) => <ColorlibStepIcon {...props} icon={icon} />}
              sx={{
                "& .MuiStepLabel-label": {
                  fontWeight: activeStep === index ? "bold" : "normal",
                  color: activeStep === index ? "primary.main" : error ? "#f44336" : "#000",
                },
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {formatString(label)}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
