import ImageNotFoundImg from "../assets/images/image-not-found.png";

export function truncateString(str: string, length: number) {
  if (!str) return "";
  let truncated = str;
  if (str.length > length) {
    truncated = str.substring(0, length / 2) + "..." + str.substring(str.length - length / 2);
  }
  return truncated;
}

export function getImageUrlFromItem(item: any) {
  const imageUrl = item?.itemGroupImageUrl || item?.imageUrl;
  const s3Path = "item-images/" + imageUrl;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;
  if (!s3Path || !cloudfrontPrefix) {
    console.error("@getImageUrlFromItem: Invalid s3Path or cloudfrontPrefix", { s3Path, cloudfrontPrefix });
    return ImageNotFoundImg;
  }
  return `${cloudfrontPrefix}/${s3Path}`;
}
