import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import snackbarReducer from "./slices/snackbar";

const appReducer = combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
});

const store = configureStore({
  reducer: appReducer,
});

export default store;
