import { Box, Button, Card, CardMedia, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import ImageNotFoundImg from "../../../assets/images/image-not-found.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getImageUrlFromReward } from "../../../utils/reward";
import { useNavigate } from "react-router-dom";
import YouWinImage from "../../../assets/images/you_win.png";
import { Info } from "@mui/icons-material";
import { scratchCardStatusEnum, scratchCardUserStatusEnum } from "../../../types/scratchCardEnum";

interface RewardCardProps {
  scratchCardDetails: any;
  reward: any;
  event: any;
  userScratchCardStatus: string;
  onClaim: (userId: string, subscriptionId: string | null, rewardId: string | null) => void;
}

export default function RewardCard({
  scratchCardDetails,
  reward,
  event,
  userScratchCardStatus,
  onClaim,
}: RewardCardProps) {
  const navigate = useNavigate();
  const userId = useSelector((state: any) => state.user.userId);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 5,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: userScratchCardStatus === scratchCardUserStatusEnum.Claimed ? "black" : "primary.main",
          color: "white",
          px: 2,
          py: 0.5,
          zIndex: 10,
          fontSize: "0.8rem",
          borderBottomLeftRadius: "0.5rem",
        }}
      >
        {userScratchCardStatus === scratchCardUserStatusEnum.Redeemed
          ? "Your Reward Awaits!"
          : userScratchCardStatus === scratchCardUserStatusEnum.RewardAssigned
          ? "Unclaimed"
          : "Claimed"}
      </Box>
      <Box
        sx={{
          textDecoration: "none",
          transition: "filter 0.2s ease-in-out",
          position: "relative",
          cursor: userScratchCardStatus === scratchCardUserStatusEnum.Claimed ? "not-allowed" : "pointer",
          pointerEvents: userScratchCardStatus === scratchCardUserStatusEnum.Claimed ? "none" : "auto",
        }}
      >
        <Box position={"relative"} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <CardMedia
            component="img"
            alt="reward-image"
            width={"100%"}
            sx={{ objectFit: "fill", aspectRatio: "1/1.2" }}
            image={
              userScratchCardStatus === scratchCardUserStatusEnum.Redeemed
                ? YouWinImage
                : reward?.image || getImageUrlFromReward(reward)
            }
            onError={(e: any) => (e.target.src = ImageNotFoundImg)}
            loading="lazy"
          />
          {userScratchCardStatus !== scratchCardUserStatusEnum.Claimed && (
            <Box
              position={"absolute"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(255,0,0,0.3)",
              }}
            >
              <Tooltip title={reward?.description} open={showTooltip}>
                <IconButton onClick={() => setShowTooltip((prev) => !prev)}>
                  <Info sx={{ fontSize: "5rem", color: "white" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {userScratchCardStatus === scratchCardUserStatusEnum.Redeemed && (
          <Stack padding={2} component={Paper} sx={{ backgroundColor: "#fafafa", borderRadius: "0.5rem" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                whiteSpace: "nowrap",
              }}
              onClick={() => navigate(`/redeem-scratch-card/${scratchCardDetails?.fullCode}`)}
            >
              Spin To Win
            </Button>
          </Stack>
        )}
        {userScratchCardStatus !== scratchCardUserStatusEnum.Redeemed && (
          <Box padding={2} component={Paper} sx={{ backgroundColor: "#fafafa", borderRadius: "0.5rem" }}>
            <Stack direction="column" spacing={1}>
              <Stack>
                <Typography variant="subtitle2" color="text.secondary">
                  Name
                </Typography>
                <Typography fontWeight={700} color="primary.main" noWrap>
                  {reward?.name?.toUpperCase() || "NO-NAME"}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="subtitle2" color="text.secondary">
                  Type
                </Typography>
                <Typography fontWeight={700} color="primary.main" noWrap>
                  {reward?.type || "NO-TYPE"}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )}
        {userScratchCardStatus === scratchCardUserStatusEnum.RewardAssigned && (
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              background: "green",
              borderRadius: "10px",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            onClick={() => onClaim(userId, reward?.subscriptionId, reward?.ripPackId)}
          >
            Claim Reward
          </Button>
        )}
      </Box>
    </Card>
  );
}
