export enum UserStepEnum {
  CreateAccount = "create_account",
  RedeemReward = "redeem_your_reward",
  UpdateUserInfo = "update_user_info",
  ReferAFriend = "refer_a_friend",
  Complete = "complete",
}

export enum UserTypeEnum {
  Basic = "basic",
  ScratchCard = "scratchCard",
}
