import { Instagram, X, YouTube, Facebook } from "@mui/icons-material";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { Button, Paper, Stack, Typography } from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";
import EmailIcon from "@mui/icons-material/Email";
import { getExternalLinksFromUser, shockemUserDefaultValues } from "../../utils/user";

export default function SocialIcons({ userDetails }: { userDetails: any }) {
  const externalLinks = getExternalLinksFromUser(userDetails);

  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      sx={{ gap: { xs: 1, sm: 1, md: 2 }, fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem" } }}
    >
      {externalLinks?.instagram && (
        <Instagram
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.instagram) {
              window.open(externalLinks?.instagram);
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      )}
      {externalLinks?.twitter && (
        <X
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.twitter) {
              window.open(externalLinks?.twitter);
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      )}
      {externalLinks?.email && (
        <EmailIcon
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.instagram) {
              window.open(externalLinks?.email);
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      )}
      {externalLinks?.website && (
        <TbWorldWww
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.website) {
              window.open(externalLinks?.website);
            }
          }}
          style={{ cursor: "pointer", flexShrink: 0 }}
        />
      )}
      {externalLinks?.discord && (
        <FaDiscord
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.discord) {
              window.open(externalLinks?.discord);
            }
          }}
          style={{ cursor: "pointer", flexShrink: 0 }}
        />
      )}
      {externalLinks?.youtube && (
        <YouTube
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.youtube) {
              window.open(externalLinks?.youtube);
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      )}
      {externalLinks?.tiktok && (
        <FaTiktok
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.tiktok) {
              window.open(externalLinks?.tiktok);
            }
          }}
          style={{ cursor: "pointer", flexShrink: 0 }}
        />
      )}
      {externalLinks?.facebook && (
        <Facebook
          fontSize="inherit"
          onClick={() => {
            if (externalLinks?.facebook) {
              window.open(externalLinks?.facebook);
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      )}
    </Stack>
  );
}
