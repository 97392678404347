import BaseAPI from "./BaseApi";

class UserSubscriptionApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/userSubscription", cache: false });
  }

  async updateUserSubscription({
    _id,
    userId,
    subscriptionId,
  }: {
    _id?: string;
    userId?: string;
    subscriptionId?: string;
  }) {
    try {
      const result = await this.axios.put("/lastUsed", { _id, userId, subscriptionId });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in UserSubscriptionApi.updateUserSubscription: " + error.message);
    }
  }

  async getUserSubscription({
    userId,
    subscriptionId,
    populate,
  }: {
    userId?: string;
    subscriptionId?: string;
    populate?: boolean;
  }) {
    try {
      const result: any = await this.axios.get("/", {
        params: { userIds: [userId], subscriptionIds: [subscriptionId], populate },
      });
      if (!result?.data?.success || !result?.data?.userSubscriptions) throw new Error("Invalid response from server");
      return result.data.userSubscriptions;
    } catch (error: any) {
      throw new Error("Error in UserSubscriptionApi.getUserSubscription: " + error.message);
    }
  }

  async createUserSubscription({ userId, subscriptionId }: { userId: string; subscriptionId: string }) {
    try {
      const result = await this.axios.post("/create", { userId, subscriptionId });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in UserSubscriptionApi.createUserSubscription: " + error.message);
    }
  }
}

export const userSubscriptionApi = new UserSubscriptionApi();
