import { TextField, Chip, Stack, Button } from "@mui/material";
import React, { useState } from "react";

interface EditSocialsProps {
  interests: Array<string>;
  setInterests: any;
}

export default function EditProfileInterests({ interests, setInterests }: EditSocialsProps) {
  const [newInterest, setNewInterest] = useState("");

  return (
    <React.Fragment>
      <Stack direction={"row"} sx={{ alignItems: "center", flexWrap: "wrap", rowGap: 1 }}>
        {interests.map((interest, index) => (
          <Chip
            sx={{ mr: 1 }}
            key={index}
            label={interest}
            onDelete={() => setInterests(interests.filter((i) => i !== interest))}
          />
        ))}
        <Stack direction={"row"} gap={1} width={"100%"}>
          <TextField
            id="newInterest"
            label="Add new interest"
            variant="outlined"
            size="small"
            value={newInterest}
            sx={{ flexGrow: 1 }}
            onChange={(e) => setNewInterest(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setInterests([...interests, newInterest]);
                setNewInterest("");
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setInterests([...interests, newInterest]);
              setNewInterest("");
            }}
          >
            ADD
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}
