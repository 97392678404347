import { useEffect, useRef, useState } from "react";
import { Box, Stack, Tab, Tabs, Typography, useScrollTrigger } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMobile } from "../hooks/useIsMobile";
import OwnerInfo from "../components/user/OwnerInfo";
import SocialIcons from "../components/user/SocialIcons";
import GridSection from "../components/user/items/GridSection";
import ScrollTopButton from "../components/common/ScrollTopButton";
import { itemApi } from "../apis/ItemApi";
import { ShockemUserType, userApi } from "../apis/UserApi";
import EditProfile from "../components/common/EditProfile";
import RewardGrid from "../components/user/rewards/RewardGrid";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionGrid from "../components/user/subscriptions/SubscriptionGrid";
import UserInterests from "../components/user/UserInterests";
import UserCoverImage from "../components/user/UserCoverImage";
import { openSnackbar } from "../redux/slices/snackbar";
import { userSubscriptionApi } from "../apis/UserSubscription";
import { scratchCardApi } from "../apis/ScratchCardApi";
import CustomStepper from "../components/common/CustomStepper";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { getNextStep, getStepsLabels } from "../utils/user";
import Leaderboard from "../components/user/leaderboard/LeaderBoardGrid";
import WelcomeUserDialog from "../components/user/WelcomeUserDialog";
import ReferFriendPopup from "../components/reward-claim/ReferFriendPopup";
import { setUser } from "../redux/slices/user";
import { UserStepEnum, UserTypeEnum } from "../types/userStageEnum";
import ProfilePicture from "../components/user/ProfilePicture";
import CollectionLeaderBoard from "./CollectionLeaderBoard";
import UserSkeleton from "../components/user/UserSkeleton";

export default function User() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state: any) => state.user.userId);
  const userSteps = useSelector((state: any) => state.user.stepProgress);
  const [steps, setSteps] = useState([]);
  const userId = params.userId || loggedInUserId;
  const isOwner = userId === loggedInUserId;
  const isMobile = useIsMobile();
  const trigger = useScrollTrigger();
  const floatOptionsRef = useRef<HTMLDivElement>(null);
  const [floatOptionsTop, setFloatOptionsTop] = useState(0);
  const [items, setItems] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState("");
  const [showWelcomeUserDialog, setShowWelcomeUserDialog] = useState(false);
  const [showReferFriendPopup, setShowReferFriendPopup] = useState(false);
  const [userDetails, setUserDetails] = useState<ShockemUserType>({
    _id: "",
    emailAddress: "",
    verifiedEmail: false,
    stepProgress: {
      type: "",
      activeStep: "",
      stepTimestamp: null,
      fullCode: "",
    },
  });
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [userScratchCards, setUserScratchCards] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showFloatOptions = !trigger || !isMobile || floatOptionsTop > 100;
  const triggerRefresh = () => setRefresh((prev) => !prev);
  useEffect(() => {
    (async () => {
      try {
        if (!userId) {
          navigate("/login");
          return;
        }
        setIsLoading(true);
        const userDetails = await userApi.getUserDetails({ userId });
        if (!userDetails) throw new Error("User not found");
        setUserDetails(userDetails);
        dispatch(setUser(userDetails));

        if (isOwner) {
          const [userSubscriptionsResult, scratchCardResults, itemResult] = await Promise.all([
            userSubscriptionApi.getUserSubscription({ userId, populate: true }),
            scratchCardApi.getPaginatedScratchCardUsers({
              page: 0,
              limit: 100,
              total: true,
              ownerUserIds: [userId],
              populate: true,
            }),
            itemApi.getItems({ ownerId: userId }),
          ]);
          if (!userSubscriptionsResult) throw new Error("Error in Fetching User Subscriptions");
          if (!scratchCardResults?.scratchCardUsers) throw new Error("No scratch cards found");
          if (!itemResult) throw new Error("Failed to fetch items");

          setUserSubscriptions(userSubscriptionsResult || []);
          setUserScratchCards(scratchCardResults.scratchCardUsers || []);
          setTabValue(
            scratchCardResults?.scratchCardUsers?.length > 0
              ? "rewards"
              : userSubscriptionsResult.length > 0
              ? "subscriptions"
              : ""
          );
          setItems(itemResult.items);
        } else {
          // setTabValue("leaderboard");
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
        dispatch(openSnackbar({ message: "Failed to fetch user details or subscriptions", severity: "error" }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId, navigate, dispatch, refresh]);

  useEffect(() => {
    function handleScroll() {
      if (floatOptionsRef.current) {
        const floatOptions = floatOptionsRef.current;
        const floatOptionsTop = floatOptions.getBoundingClientRect().top;
        setFloatOptionsTop(floatOptionsTop);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Manually trigger step updates as necessary
  useEffect(() => {
    async function updateUserDetails(userUpdateObj: any) {
      const formData = new FormData();
      formData.append("filter", JSON.stringify({ _id: loggedInUserId }));
      formData.append("userObjUpdates", JSON.stringify(userUpdateObj));
      try {
        const result = await userApi.updateUserDetails(formData);
        if (!result) throw new Error("Error in updating user details");
        dispatch(setUser(result.user));
      } catch (error: any) {
        dispatch(openSnackbar({ message: "Error in updating user details: " + error.message, severity: "error" }));
      }
    }

    function handleStepUpdate() {
      if (!userSteps || userSteps.activeStep === "complete") return;
      const labels = getStepsLabels();
      const stepsList: any = {
        basic: labels.basic.map((label, index) => {
          const actions = [null, () => setEditMode(true), () => navigate("/refer-a-friend"), null];
          const icons = [<PersonAddIcon />, <EditIcon />, <ConnectWithoutContactIcon />, <CheckCircleIcon />];
          return { label, icon: icons[index], action: actions[index] };
        }),
        scratchCard: labels.scratchCard.map((label, index) => {
          const actions = [
            null,
            () => navigate(`/redeem-scratch-card/${userSteps?.fullCode}`),
            () => setEditMode(true),
            () => navigate("/refer-a-friend"),
            null,
          ];
          const icons = [
            <PersonAddIcon />,
            <CardGiftcardIcon />,
            <EditIcon />,
            <ConnectWithoutContactIcon />,
            <CheckCircleIcon />,
          ];
          return { label, icon: icons[index], action: actions[index] };
        }),
        // promoCode: labels.promoCode.map((label, index) => {
        //   const actions = [
        //     null,
        //     () => navigate(`/redeem-promo-code/${userSteps?.fullCode}`),
        //     () => setEditMode(true),
        //     () => navigate("/refer-a-friend"),
        //     null,
        //   ];
        //   const icons = [
        //     <PersonAddIcon />,
        //     <CardGiftcardIcon />,
        //     <EditIcon />,
        //     <ConnectWithoutContactIcon />,
        //     <CheckCircleIcon />,
        //   ];
        //   return { label, icon: icons[index], action: actions[index] };
        // }),
      };

      setSteps(stepsList[userSteps.type || UserTypeEnum.Basic]);

      if (userSteps.activeStep === UserStepEnum.UpdateUserInfo && !userSteps.stepTimestamp) {
        setShowWelcomeUserDialog(true);
        updateUserDetails({ stepProgress: { ...userSteps, stepTimestamp: Date.now() } });
      }

      if (userSteps.activeStep === UserStepEnum.ReferAFriend && !userSteps.stepTimestamp) {
        setShowReferFriendPopup(true);
        updateUserDetails({ stepProgress: { ...userSteps, stepTimestamp: Date.now() } });
      }
    }
    handleStepUpdate();
  }, [userSteps, navigate, dispatch, loggedInUserId]);

  return isLoading ? (
    <UserSkeleton />
  ) : (
    <Box sx={{ height: "auto", width: "100%", overflowX: "hidden" }}>
      <Box sx={{ height: { xs: "4.5rem", sm: "4.5rem" } }} />

      {isOwner && userSteps?.activeStep && userSteps && userSteps.activeStep !== UserStepEnum.Complete && (
        <CustomStepper
          steps={steps}
          activeStep={steps.findIndex(
            (step: any) => step.label.toLowerCase() === (userDetails?.stepProgress?.activeStep || userSteps?.activeStep)
          )}
        />
      )}

      <UserCoverImage userData={userDetails} editMode={editMode} />
      <Box
        display={"flex"}
        gap={2}
        sx={{
          width: "100%",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display={"flex"}
          gap={2}
          sx={{
            width: "94%",
            position: "relative",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: { xs: "25vw", sm: "25vw", md: "15rem", lg: "15rem" },
              height: { xs: "calc(25vw/2)", sm: "calc(25vw/2)", md: "calc(15rem/2)", lg: "calc(15rem/2)" },
              position: "relative",
            }}
          >
            <ProfilePicture userDetails={userDetails} />
          </Box>

          <Box
            width={{
              xs: "calc(100% - 25vw)",
              sm: "calc(100% - 25vw)",
              md: "calc(100% - 15rem)",
              lg: "calc(100% - 15rem)",
            }}
            mt={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            display={"flex"}
            sx={{ flexGrow: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: "stretch" }}
          >
            {!isMobile ? (
              <OwnerInfo userDetails={userDetails} setEditMode={setEditMode} />
            ) : (
              <SocialIcons userDetails={userDetails} />
            )}
          </Box>
        </Box>
        {isMobile && (
          <Box
            display={"flex"}
            gap={2}
            sx={{
              width: "94%",
              position: "relative",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <OwnerInfo userDetails={userDetails} setEditMode={setEditMode} />
          </Box>
        )}
      </Box>

      <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 2, mb: 2, maxWidth: "100%" }}>
        {/* <Fade ref={floatOptionsRef} in={showFloatOptions}>
          <Box position={"sticky"} top={"5rem"} sx={{ height: { xs: "7.4rem", sm: "3.2rem" }, zIndex: 10 }}>
            <FilterSection />
            <SortSection />
          </Box>
        </Fade> */}

        <Stack p={2} spacing={2} sx={{ width: "100%", height: "auto", boxSizing: "border-box" }}>
          <Box sx={{ borderBottom: "1px solid grey", borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              sx={{ width: { xs: "100%", sm: "auto" } }}
              onChange={(_, value) => setTabValue(value)}
            >
              {/* <Tab label="Items" value="items" /> */}

              {isOwner &&
                (userScratchCards?.length > 0 || userSubscriptions?.length > 0) &&
                userScratchCards &&
                userScratchCards.length && <Tab label="Rewards" value="rewards" />}
              {isOwner &&
                (userScratchCards?.length > 0 || userSubscriptions?.length > 0) &&
                userSubscriptions &&
                userSubscriptions.length && <Tab label="Subscriptions" value="subscriptions" />}

              {/* <Tab label="Leaderboard" value="leaderboard" /> */}
              {/* <Tab label="RIP Packs" value="ripPacks" /> */}
            </Tabs>
          </Box>

          {
            {
              items: <GridSection items={items} />,
              rewards: (
                <RewardGrid userId={userId} userScratchCards={userScratchCards} triggerRefresh={triggerRefresh} />
              ),
              subscriptions: <SubscriptionGrid userId={userId} userSubscriptions={userSubscriptions} />,
              // leaderboard: <CollectionLeaderBoard userId={userId} />,
              default: null,
              // ripPacks: <RipPackGrid userId={userId} items={items} />,
            }[tabValue]
          }
        </Stack>
      </Stack>

      {/* Float elements */}
      <ScrollTopButton />
      <EditProfile
        open={editMode}
        onClose={() => setEditMode(false)}
        updateUserData={setUserDetails}
        steps={steps}
        userData={{ ...userDetails, userId: userDetails?._id }}
        closeOnSave
      />
      <WelcomeUserDialog
        open={showWelcomeUserDialog}
        onClose={() => setShowWelcomeUserDialog(false)}
        onContinue={() => {
          setShowWelcomeUserDialog(false);
          setEditMode(true);
        }}
      />
      {showReferFriendPopup && (
        <ReferFriendPopup onClose={() => setShowReferFriendPopup(false)} onOpen={() => setShowReferFriendPopup(true)} />
      )}
    </Box>
  );
}
