import orangeBackground from "../../assets/images/orangebackground.png";
import congratsLogo from "../../assets/images/congratsLogo.png";
import { Avatar, Box, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ShockemPopupBox({
  bannerImage,
  bannerTextImage,
  bannerLogoImage,
  onClose,
  children,
}: {
  bannerImage?: any;
  bannerTextImage?: any;
  bannerLogoImage?: any;
  onClose?: any;
  children?: any;
}) {
  return (
    <Paper
      sx={{
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "1rem",
        width: "100%",
        height: "100%",
        boxShadow: "10px 1rem 1rem 0.0rem grey",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "1rem 1rem 0 0",
          position: "relative",
          backgroundImage: `url(${bannerImage || orangeBackground})`,
          backgroundSize: "105% 105%",
          backgroundPosition: "center",
          width: "100%",
          aspectRatio: `1700/500`,
        }}
      >
        {onClose && (
          <IconButton
            onClick={() => {
              onClose();
            }}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {bannerTextImage && (
          <Box
            component="img"
            src={bannerTextImage}
            sx={{ position: "absolute", left: "6%" }}
            alt="Shock'em Rewards"
            width="80%"
          />
        )}
        {bannerLogoImage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "55%",
              left: "0%",
              height: "90%", // Width is 30% of the parent container
              width: "100%",
              borderRadius: "50%", // Makes the square into a circle
              cursor: "pointer", // Changes the cursor to a pointer (hand) on hover
            }}
          >
            <Avatar
              src={bannerLogoImage || congratsLogo}
              sx={{
                height: "100%", // Width is 30% of the parent container
                width: "auto",
                aspectRatio: "1/1",
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
          width: "100%",
          height: "70%",
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: "5%",
          marginTop: bannerLogoImage ? "10%" : "",
        }}
      >
        {children}
      </Box>
    </Paper>
  );
}
