import { Box, Button, Card, CardMedia, Divider, Tooltip, Typography } from "@mui/material";
import ImageNotFoundImg from "../../../assets/images/image-not-found.png";
import { getImageUrlFromSubscription } from "../../../utils/subscription";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { formatDate, timeDifference } from "../../../utils/time";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../../redux/slices/snackbar";
import CustomBookmark from "../../common/CustomBookmark";
interface SubscriptionCardProps {
  userSubscription: any;
  onOpen: (subscriptionId: string) => void;
}

export default function SubscriptionCard({ userSubscription, onOpen }: SubscriptionCardProps) {
  const userId = useSelector((state: any) => state.user.userId);
  const isActive = new Date(userSubscription?.subscriptionDetails?.activationTime) <= new Date();

  const dispatch = useDispatch();

  function handleOpen() {
    window.open(userSubscription.subscriptionDetails.url, "_blank");
    if (isActive) onOpen(userSubscription?._id);
    else dispatch(openSnackbar({ message: "Subscription not active", severity: "error" }));
  }

  if (userId !== userSubscription.userId) return null;

  return (
    <Card sx={{ boxShadow: 4, overflow: "hidden", borderRadius: 2 }} onClick={() => handleOpen()}>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ overflow: "hidden", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <CardMedia
            component="img"
            alt="subscription-image"
            width={"100%"}
            sx={{ objectFit: "fill", aspectRatio: "1/1.2" }}
            image={getImageUrlFromSubscription(userSubscription?.subscriptionDetails) || ""}
            onError={(e: any) => (e.target.src = ImageNotFoundImg)}
            loading="lazy"
          />
        </Box>
        {!userSubscription?.firstUseTime && <CustomBookmark text={"New"} />}
        <Tooltip title={userSubscription?.subscriptionDetails?.name || "No Name"} arrow>
          <Button
            variant="contained"
            sx={{
              maxWidth: "100%",
              textDecoration: "underline",
              cursor: "pointer",
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, 50%)",
              zIndex: 5,
              backgroundColor: "#2fd484",
            }}
          >
            Read
          </Button>
        </Tooltip>
      </Box>

      <Box
        sx={{
          padding: "0.2rem 1rem",
          backgroundColor: "background.default",
          boxShadow: 3,
          position: "relative",
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            top: "1.4rem",
            right: "0.5rem",
            alignItems: "center",
            display: "flex",
          }}
        >
          {userSubscription?.lastUseTime ? (
            <>
              <Tooltip title="Last Opened" arrow>
                <SlowMotionVideoIcon sx={{ marginRight: "0.25rem", color: "primary" }} />
              </Tooltip>
              <Typography variant="body2" color="primary">
                {timeDifference(userSubscription?.lastUseTime) || "Ready to Use"}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="primary" fontWeight="bold" sx={{ marginRight: "0.25rem" }}>
              Ready for first Read
            </Typography>
          )}
        </Box> */}
        {/* Last Use Time */}
        {/* <Box
          sx={{ marginBottom: "1rem", marginTop: "1.2rem", display: { xs: "none", sm: "flex" } }}
          flexDirection={"column"}
        >
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Series
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userSubscription?.subscriptionDetails?.series}
          </Typography>
          <Divider sx={{ marginTop: "0.5rem" }} />
        </Box> */}

        {/* Activation and Expiration Time */}
        <Box
          display="flex"
          justifyContent={{ xs: "center", sm: "space-between" }}
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ marginTop: "1rem" }}
        >
          {/* Activation Time */}
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Activation Time
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDate(userSubscription?.subscriptionDetails?.activationTime)}
            </Typography>
          </Box>
          <Divider sx={{ ml: "0.5rem" }} />
          {/* Expiration Time */}
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Expiration Time
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDate(userSubscription?.subscriptionDetails?.expirationTime)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
