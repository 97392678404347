import { Box, Button, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Instagram, Add, Remove, X, YouTube, Facebook } from "@mui/icons-material";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import React, { useEffect } from "react";
import ExploreIcon from "@mui/icons-material/Explore";
import { validateSocialMediaLink } from "../../utils/validation";

interface EditSocialsProps {
  socialMediaLinks: any;
  setSocialMediaLinks: any;
  socialMediaLinksValidation: any;
  setSocialMediaLinksValidation: any;
}

const socialMediaOptions = [
  { value: "twitter", icon: <X sx={{ fontSize: "inherit" }} /> },
  { value: "instagram", icon: <Instagram sx={{ fontSize: "inherit" }} /> },
  { value: "website", icon: <TbWorldWww style={{ fontSize: "inherit" }} /> },
  { value: "tiktok", icon: <FaTiktok style={{ fontSize: "inherit" }} /> },
  { value: "youtube", icon: <YouTube sx={{ fontSize: "inherit" }} /> },
  { value: "discord", icon: <FaDiscord style={{ fontSize: "inherit" }} /> },
  { value: "facebook", icon: <Facebook sx={{ fontSize: "inherit" }} /> },
];

export default function EditProfileSocials({
  socialMediaLinks,
  setSocialMediaLinks,
  socialMediaLinksValidation,
  setSocialMediaLinksValidation,
}: EditSocialsProps) {
  useEffect(() => {
    if (socialMediaLinks.hasOwnProperty("website") && !validateSocialMediaLink(socialMediaLinks["website"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, website: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, website: "" }));
    }

    if (socialMediaLinks.hasOwnProperty("instagram") && !validateSocialMediaLink(socialMediaLinks["instagram"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, instagram: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, instagram: "" }));
    }

    if (socialMediaLinks.hasOwnProperty("twitter") && !validateSocialMediaLink(socialMediaLinks["twitter"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, twitter: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, twitter: "" }));
    }
    if (socialMediaLinks.hasOwnProperty("tiktok") && !validateSocialMediaLink(socialMediaLinks["tiktok"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, tiktok: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, tiktok: "" }));
    }

    // YouTube validation
    if (socialMediaLinks.hasOwnProperty("youtube") && !validateSocialMediaLink(socialMediaLinks["youtube"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, youtube: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, youtube: "" }));
    }

    // Discord validation
    if (socialMediaLinks.hasOwnProperty("discord") && !validateSocialMediaLink(socialMediaLinks["discord"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, discord: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, discord: "" }));
    }

    // Facebook validation
    if (socialMediaLinks.hasOwnProperty("facebook") && !validateSocialMediaLink(socialMediaLinks["facebook"])) {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, facebook: "Invalid URL" }));
    } else {
      setSocialMediaLinksValidation((prev: any) => ({ ...prev, facebook: "" }));
    }
  }, [socialMediaLinks, setSocialMediaLinksValidation]);

  function handleAddSocialMedia() {
    setSocialMediaLinks((prev: any) => ({ ...prev, "": "" }));
  }

  function handleRemoveSocialMedia(platform: string) {
    const updatedLinks = { ...socialMediaLinks };
    delete updatedLinks[platform];
    setSocialMediaLinks(updatedLinks);
  }

  function removePrefix(link: string) {
    return link.replace("https://", "");
  }

  return (
    <React.Fragment>
      <Stack spacing={2}>
        {Object.entries(socialMediaLinks || {}).map(([platform, link]: any) => {
          return (
            <Stack key={platform} direction="row" alignItems="center" spacing={1}>
              <TextField
                select
                label="Social Media"
                value={platform}
                onChange={(e) => {
                  const updatedLinks = { ...socialMediaLinks };
                  const newPlatform = e.target.value;
                  updatedLinks[newPlatform] = updatedLinks[platform] || "";
                  if (newPlatform !== platform) delete updatedLinks[platform];
                  setSocialMediaLinks(updatedLinks);
                }}
                sx={{ flex: 1, height: "unset" }}
              >
                {socialMediaOptions
                  .filter(
                    (option) => !Object.keys(socialMediaLinks).includes(option.value) || platform === option.value
                  )
                  .map((option, index) => (
                    <MenuItem key={index} value={option.value} sx={{ fontSize: "1.1rem" }}>
                      <Stack direction={"row"} sx={{ alignItems: "center", gap: 1 }}>
                        <React.Fragment>{option.icon}</React.Fragment>
                        <Typography>{option.value.charAt(0).toUpperCase() + option.value.slice(1)}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label="Social Media Link"
                value={removePrefix(link)}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                  },
                }}
                onChange={(e) => {
                  const updatedLinks = { ...socialMediaLinks };
                  updatedLinks[platform] = e.target.value;
                  setSocialMediaLinks(updatedLinks);
                }}
                helperText={socialMediaLinksValidation[platform] ? "Please enter a valid link" : ""}
                sx={{ flex: 2 }}
              />
              <IconButton onClick={() => handleRemoveSocialMedia(platform)} color="error">
                <Remove />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
      {Object.keys(socialMediaLinks).length < socialMediaOptions.length && (
        <Button onClick={handleAddSocialMedia} startIcon={<Add />} variant="outlined">
          Add Social Media
        </Button>
      )}
    </React.Fragment>
  );
}
