import RipPackImage from "../assets/images/ripPack.png";
import { scratchCardUserStatusEnum } from "../types/scratchCardEnum";

export function getImageUrlFromRipPack(ripPackDetails: any, userScratchCardDetails: any) {
  if (userScratchCardDetails.status !== scratchCardUserStatusEnum.Claimed) {
    return RipPackImage;
  }

  const imageUrl = ripPackDetails?.imageUrl;
  const _id = ripPackDetails?._id;

  if (!imageUrl && !_id) return null;

  const s3Path = `${"ripPack"}-images/${imageUrl || _id}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for ripPack`, { s3Path, cloudfrontPrefix });
    return null;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}
