import BaseAPI from "./BaseApi";

class RipPackApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/ripPack", cache: false });
  }

  async getRipPackDetails({
    ripPackIds,
    fromTimestamp,
    toTimestamp,
  }: {
    ripPackIds: string[];
    fromTimestamp?: number;
    toTimestamp?: number;
  }) {
    try {
      const result: any = await this.axios.get("", {
        params: {
          ripPackIds,
          fromTimestamp,
          toTimestamp,
        },
      });
      if (!result?.data?.success || !result?.data?.ripPacksData) throw new Error("Invalid response from server");
      return result.data.ripPacksData;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.getRipPackDetails: " + error.message);
    }
  }
}

export const ripPackApi = new RipPackApi();
