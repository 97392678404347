import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Snackbar } from "@mui/material";
import { closeSnackbar } from "../../redux/slices/snackbar";
import MuiAlert from "@mui/material/Alert";
import { Slide, SlideProps } from "@mui/material";

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export default function SnackbarProvider({ children }: SnackbarProviderProps) {
  const dispatch = useDispatch();
  const { isOpen, message, severity } = useSelector((state: any) => state.snackbar);

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") return;
    dispatch(closeSnackbar());
  }

  const Transition = React.forwardRef(
    (props: SlideProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => {
      return <Slide direction="up" ref={ref} {...props} />;
    }
  );

  return (
    <React.Fragment>
      {children}
      {isOpen && (
        <Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={1500}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <MuiAlert elevation={6} onClose={handleClose} sx={{ width: "100%" }} severity={severity}>
              {message}
            </MuiAlert>
          </Snackbar>
        </Box>
      )}
    </React.Fragment>
  );
}
