import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "",
  severity: "success",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || "success";
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
      state.message = "";
      state.severity = "success";
    },
  },
});

const snackbarReducer = snackbarSlice.reducer;

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarReducer;
