import { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import RankTable from "./RankTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getImageUrlFromItem } from "../../utils/item";

const Category = ({ collectionDetailsWithItemGroups }: { collectionDetailsWithItemGroups?: any }) => {
  const [itemGroupTitleToShowDetails, setItemGroupTitleToShowDetails] = useState<Record<string, boolean>>({});

  return (
    <Box sx={{ marginBottom: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundImage: "linear-gradient(to right, #ff7e5f, #feb47b)",
          p: 2,
          m: 2,
          marginBottom: 1,
          borderRadius: 2,
          color: "white",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          CATEGORY: {collectionDetailsWithItemGroups?.collectionDetails?.title}
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          Total Registry Points: {collectionDetailsWithItemGroups?.collectionDetails?.totalRegistryPoints}
        </Typography>
      </Box>

      {(collectionDetailsWithItemGroups?.itemGroups || []).map((itemGroupDetailsWithOwners: any, index: number) => (
        <Box
          sx={{ marginTop: 2, color: "white", marginLeft: { xs: 2, sm: 10 }, marginRight: { xs: 2, sm: 10 } }}
          component={Paper}
        >
          <Box
            p={1}
            sx={{
              width: "100%",
              boxSizing: "border-box",
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 2,
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              ITEM/TITLE: {itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupTitle || ""}
            </Typography>
            {!itemGroupTitleToShowDetails[itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupTitle] ? (
              <KeyboardArrowDownIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setItemGroupTitleToShowDetails((prev) => {
                    let key = itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupTitle || "";
                    return { ...prev, [key]: true };
                  });
                }}
              ></KeyboardArrowDownIcon>
            ) : (
              <KeyboardArrowUpIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setItemGroupTitleToShowDetails((prev) => {
                    let key = itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupTitle || "";
                    return { ...prev, [key]: false };
                  });
                }}
              >
                {" "}
              </KeyboardArrowUpIcon>
            )}
          </Box>
          {itemGroupTitleToShowDetails[itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupTitle] && (
            <Box
              p={1}
              sx={{
                backgroundImage: "linear-gradient(to right, #ff7e5f, #feb47b)",
                color: "black",
                display: "flex",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {/* <Typography sx={{ marginLeft: 2, fontWeight: "bold", fontSize: "1.2rem" }}>IMAGE</Typography> */}
              <img
                style={{ marginLeft: 10, fontWeight: "bold" }}
                src={getImageUrlFromItem(itemGroupDetailsWithOwners?.itemGroupDetails)}
                alt="Item"
                width={100}
              />
              <Typography sx={{ marginLeft: 2, fontWeight: "bold", fontSize: "1.2rem" }}>
                {/* DESC */}
                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  {itemGroupDetailsWithOwners?.itemGroupDetails?.itemGroupDescription || ""}
                </Typography>
              </Typography>
            </Box>
          )}
          <RankTable owners={itemGroupDetailsWithOwners?.owners || []} />
        </Box>
      ))}
    </Box>
  );
};

export default Category;
