import { Edit } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { getImageUrlFromCoverImageField } from "../../utils/user";

export default function UserCoverImage({
  userData = {},
  editMode = false,
  editedCoverImage,
  onEdit,
}: {
  userData: any;
  editMode?: boolean;
  editedCoverImage?: any;
  onEdit?: (event: any) => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src={editedCoverImage?.previewUrl || getImageUrlFromCoverImageField(userData?.coverImage)}
        alt="cover-image"
        sx={{
          width: "100%",
          aspectRatio: "4.5 / 1",
          objectFit: "cover",
          boxShadow: 3,
        }}
      />
      {editMode && onEdit && (
        <IconButton
          component="label"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            bgcolor: "white",
            boxShadow: 1,
            borderRadius: "50%",
            fontSize: { xs: "1rem", sm: "1.2rem" },
            padding: { xs: 0.3, sm: 0.5 },
          }}
        >
          <Edit />
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => {
              onEdit(e);
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
