import { Box, Card, CardMedia, Paper, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ImageNotFoundImg from "../../../assets/images/image-not-found.png";
import { getImageUrlFromItem } from "../../../utils/item";

interface TokenCardProps {
  item: any;
}

export default function TokenCard({ item }: TokenCardProps) {
  return (
    <Card sx={{ borderRadius: "0.5rem", boxShadow: 5 }}>
      <Box component={Link} to={`/item/${item.collectionId}/${item.numberId}`} sx={{ textDecoration: "none" }}>
        <CardMedia
          component="img"
          alt="item-image"
          sx={{ objectFit: "cover", aspectRatio: "1/1" }}
          image={getImageUrlFromItem(item)}
          onError={(e: any) => (e.target.src = ImageNotFoundImg)}
          loading="lazy"
        />
        <Box padding={1} component={Paper}>
          <Stack direction={"row"}>
            <Typography whiteSpace="nowrap">{item.collectionName || "NO-NAME"}</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography>ID:</Typography>
            <Typography color="primary.main" fontWeight={800}>
              {item.numberId}
            </Typography>
          </Stack>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}></Box>
        </Box>
      </Box>
    </Card>
  );
}
