import { useEffect, useRef, useState } from "react";
import { AppBar, Avatar, Box, Button, IconButton, Popover, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Login, Logout, Settings } from "@mui/icons-material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { userApi } from "../../apis/UserApi";
import { useDispatch, useSelector } from "react-redux";
import { getAuthCookies } from "../../utils/auth";
import ShockemMarketLogo from "../../assets/images/shockem-logo.png";
import { logoutUser } from "../../redux/slices/user";
import { useAuthBasedRedirect } from "../../hooks/useAuthBasedRedirect";
import { getImageUrlFromProfileImageField } from "../../utils/user";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

export default function Header({ authBasedRedirect = true }: { authBasedRedirect?: boolean }) {
  useAuthBasedRedirect({ authBasedRedirect });
  const location = useLocation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [imageFailed, setImageFailed] = useState(false);
  const moreButtonRef = useRef(null);
  const user = useSelector((state: any) => state.user) || getAuthCookies();
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logoutUser());
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!user || !user?.userId) return;
      userApi.updateLastActive({ userId: user?.userId }).catch(() => {
        console.error("Error in updating last active");
      });
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(255,255,255,0.6)",
          backdropFilter: "blur(1rem)",
          boxShadow: "3rem",
          height: "4.5rem",
          width: "100vw",
          left: 0,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ height: "100%", padding: "1rem", boxSizing: "border-box" }}
        >
          {
            <IconButton
              onClick={() => setShowMenu(!showMenu)}
              color="primary"
              aria-label="open-menu"
              sx={{ visibility: user?.userId ? "visible" : "hidden" }}
            >
              <MenuIcon sx={{ fontSize: "2.5rem" }} />
            </IconButton>
          }
          <Box
            component={Link}
            to={`/`}
            display="flex"
            alignItems="center"
            aria-label="return-home"
            ml={1}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box component={"img"} src={ShockemMarketLogo} sx={{ height: { sm: "2.5rem", xs: "2rem" } }} />
          </Box>
          <Box display="flex" alignItems="center">
            {user.userId && (
              <IconButton onClick={() => setShowMore(true)} color="primary" aria-label="open-user-profile">
                {!imageFailed ? (
                  <Box
                    component={"img"}
                    src={getImageUrlFromProfileImageField(user.profileImage)}
                    sx={{ width: "2.5rem", aspectRatio: "1/1", borderRadius: "50%" }}
                    onError={() => setImageFailed(true)}
                  />
                ) : (
                  <Avatar sx={{ color: "primary" }} />
                )}
              </IconButton>
            )}
            <IconButton
              onClick={() => setShowMore(true)}
              ref={moreButtonRef}
              color="primary"
              aria-label="open-user-profile"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>

      {
        <>
          {/* Dialogs */}
          <Menu open={showMenu} onClose={() => setShowMenu(false)} />
          <Popover
            open={showMore}
            onClose={() => setShowMore(false)}
            anchorEl={moreButtonRef.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{ marginTop: "0.5rem" }}
          >
            <Box display="flex" flexDirection="column" alignItems="left" gap="0.5rem">
              {/* <Button variant="outlined" startIcon={<Settings />}>
              Setting
            </Button> */}
              {!(location.pathname === "/login" || location.pathname === "/signup") ? (
                <Button variant="outlined" onClick={handleLogout} startIcon={<Logout />}>
                  Logout
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/login");
                    }}
                    startIcon={<Login />}
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/signup");
                    }}
                    startIcon={<SpaceDashboardIcon />}
                  >
                    SIGN UP
                  </Button>
                </>
              )}
            </Box>
          </Popover>
        </>
      }
    </Box>
  );
}
