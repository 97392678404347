import BaseAPI from "./BaseApi";

class ScratchCardApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/scratchCard", cache: false });
  }

  async claimScratchCard({ scratchCardId, userId }: { scratchCardId: string; userId: string }): Promise<any> {
    try {
      const result = await this.axios.post("/claimReward", { scratchCardId, ownerUserId: userId });
      if (!result.data || !result.data?.success || !result.data?.scratchCardDetails)
        throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      throw new Error("Error in ScratchCardApi.claimScratchCard: " + error.message);
    }
  }

  async getScratchCardDetails({ fullCode }: { fullCode: string; userId?: string }) {
    try {
      const result = await this.axios.get("/details", {
        params: { fullCode },
      });
      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@ScratchCardApi.getItems:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.getItems: ${response}`);
    }
  }

  async redeemScratchCard({ ownerUserId, scratchCardId }: { ownerUserId: string; scratchCardId: string }) {
    try {
      const result = await this.axios.post("/redeem", {
        ownerUserId,
        scratchCardId,
      });
      if (!result.data || !result.data.updatedScratchCardDetails) throw new Error("Invalid response from server");
      return result.data.updatedScratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@ScratchCardApi.claimScratchCard:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.claimScratchCard: ${response}`);
    }
  }

  async getRewardForScratchCard({ scratchCardId }: { scratchCardId: string }) {
    try {
      if (!scratchCardId) return null;
      const result = await this.axios.post("/reward", {
        scratchCardId,
      });
      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error("@ScratchCardApi.getRewardForScratchCard:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.getRewardForScratchCard: ${response}`);
    }
  }

  async updateRewardDisplayedScratchCard({
    scratchCardId,
    ownerUserId,
  }: {
    scratchCardId: string;
    ownerUserId: string;
  }) {
    try {
      if (!scratchCardId) return null;
      const result = await this.axios.post("/display", {
        scratchCardId,
        ownerUserId,
      });
      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      console.error(
        "@ScratchCardApi.updateRewardDisplayedScratchCard:",
        { response: response },
        { error: error.message }
      );
      throw new Error(`@ScratchCardApi.updateRewardDisplayedScratchCard: ${response}`);
    }
  }

  async getPaginatedScratchCardUsers({
    scratchCardIds,
    ownerUserIds,
    fromTimeStamp,
    toTimeStamp,
    status,
    limit,
    page,
    sortKey,
    sortDirection,
    total = false,
    populate,
  }: {
    scratchCardIds?: string[];
    ownerUserIds?: string[];
    fromTimeStamp?: number;
    toTimeStamp?: number;
    status?: string | string[];
    limit?: number;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
    populate?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/scratchCardUsers", {
        params: {
          scratchCardIds,
          ownerUserIds,
          fromTimeStamp,
          toTimeStamp,
          status,
          limit,
          page,
          sortKey,
          sortDirection,
          total,
          populate,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.scratchCardUsers)
        throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in ScratchCardUserApi.getPaginatedScratchCardUsers: " + error.message);
    }
  }
}

export const scratchCardApi = new ScratchCardApi();
