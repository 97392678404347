import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { userSubscriptionApi } from "../../../apis/UserSubscription";
import SubscriptionCard from "./SubscriptionCard";
import React from "react";

interface SubscriptionGridProps {
  userId?: string;
  userSubscriptions: any[];
}

export default function SubscriptionGrid({ userSubscriptions, userId }: SubscriptionGridProps) {
  async function updateUserActivity(userSubscriptionId: string) {
    try {
      if (!userSubscriptionId) return;
      const result = await userSubscriptionApi.updateUserSubscription({ _id: userSubscriptionId });
      if (!result) throw new Error("No Updated User Subscription");
      return result.data;
    } catch (error: any) {
      console.error("Error in updateUserActivity:", error);
    }
  }

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <Grid container spacing={2}>
        {userSubscriptions && userSubscriptions.length > 0 ? (
          <React.Fragment>
            {userSubscriptions.map((userSubscription: any, index: number) => (
              <Grid key={index} size={{ xs: 6, sm: 2 }}>
                <SubscriptionCard key={index} userSubscription={userSubscription} onOpen={updateUserActivity} />
              </Grid>
            ))}
          </React.Fragment>
        ) : (
          <Box p={2}>
            <Typography>No Subscription Found</Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
}
