import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const LoadingContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  backdropFilter: "blur(5px)",
  zIndex: 1300,
});

const LoadingBox = styled(Box)({
  textAlign: "center",
});

const LineGraphContainer = styled(Box)({
  width: "300px",
  height: "200px",
  overflow: "hidden",
  position: "relative",
  margin: "20px auto", // Center horizontally
  display: "flex", // Flexbox for alignment
  alignItems: "center", // Center vertically
  justifyContent: "center", // Center horizontally
});

const MovingLine = styled("svg")(({ theme }) => ({
  width: "100%",
  height: "100%",
  "& path": {
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    fill: "none",
    strokeDasharray: "400",
    strokeDashoffset: "0",
    animation: "dash 0.7s linear infinite",
  },
  "@keyframes dash": {
    "0%": { strokeDashoffset: 400 },
    "100%": { strokeDashoffset: 0 },
  },
}));

interface AnalyticsLoadingProps {
  open: boolean;
  text?: string;
}

export default function AnalyticsLoading({ open, text = "Loading..." }: AnalyticsLoadingProps) {
  if (!open) return null;
  return (
    <LoadingContainer>
      <LoadingBox>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "white",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>
        <LineGraphContainer>
          <MovingLine viewBox="0 0 100 50">
            <path d="M0,40 L10,30 L20,20 L30,30 L40,10 L50,30 L60,20 L70,25 L80,15 L90,20 L100,10" />
          </MovingLine>
        </LineGraphContainer>
      </LoadingBox>
    </LoadingContainer>
  );
}
