import {
  AccountBox,
  ChevronLeft,
  Collections,
  ContactSupport,
  Email,
  Login,
  Message,
  Phone,
  Settings,
} from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Copyright from "./Copyright";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

interface MenuProps {
  open: boolean;
  onClose: () => void;
}

export default function Menu({ open, onClose }: MenuProps) {
  const url = window.location.pathname;

  const loggedInUserId = useSelector((state: any) => state.user.userId);

  useEffect(() => {
    onClose();
  }, [url]);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box sx={{ width: { xs: "100vw", sm: "20vw" } }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={2}
          boxSizing={"border-box"}
          height={"4.5rem"}
          boxShadow={3}
        >
          <Typography variant="h5">Menu</Typography>
          <IconButton onClick={onClose}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </Stack>
        <Stack p={2}>
          <MenuItem component={Link} to="/user">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <AccountBox />
              <Typography>User Page</Typography>
            </Stack>
          </MenuItem>
          {/* <MenuItem component={Link} to="/item/test">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <Collections />
              <Typography>Item Page</Typography>
            </Stack>
          </MenuItem> */}
          <MenuItem component={Link} to="/leaderBoard">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <LeaderboardIcon />
              <Typography>LeaderBoard</Typography>
            </Stack>
          </MenuItem>
          <MenuItem component={Link} to="/refer-a-friend">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <Collections />
              <Typography>Reward A Friend</Typography>
            </Stack>
          </MenuItem>
          <MenuItem component={Link} to="/contact-us">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <ContactSupport />
              <Typography>Contact Us</Typography>
            </Stack>
          </MenuItem>
          {/* <Divider />
          <MenuItem component={Link} to="/user">
            <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
              <Settings />
              <Typography>Setting</Typography>
            </Stack>
          </MenuItem> */}
          {!loggedInUserId && (
            <>
              <Divider />
              <MenuItem component={Link} to="/login">
                <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                  <Login />
                  <Typography>Login</Typography>
                </Stack>
              </MenuItem>
              <MenuItem component={Link} to="/signup">
                <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                  <AppRegistrationIcon />
                  <Typography>Register</Typography>
                </Stack>
              </MenuItem>
            </>
          )}
          <Divider />
          <Stack direction={"row"} justifyContent={"center"} gap={2}>
            <IconButton>
              <Email />
            </IconButton>
            <IconButton>
              <ContactSupport />
            </IconButton>
            <IconButton>
              <Phone />
            </IconButton>
            <IconButton>
              <Message />
            </IconButton>
          </Stack>
          <Box mt={2}>
            <Copyright />
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
}
