import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#f05725",
      light: "#f9e9e9",
      dark: "#f05725",
    },
    secondary: {
      main: "#424242",
      light: "#6d6d6d",
      dark: "#424242",
    },
  },
  typography: {
    fontFamily: "Trebuchet MS",
  },
});

export const linearGradients = {
  primaryLeftToRight: "linear-gradient(to right, #f05725, #f9e9e9)",
  secondary: "linear-gradient(to right, #f0a725, #f09e25)",
};
