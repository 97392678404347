import React from "react";
import { Avatar, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { getImageUrlFromProfileImageField } from "../../utils/user";

const RankTable = ({ owners }: { owners: any }) => {
  return (
    <Box sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem", padding: "0.8rem" }}>Rank</TableCell>
            <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem", padding: "0.8rem" }}>User</TableCell>
            <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem", padding: "0.8rem" }}>Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(owners || []).map((ownerDetailsWithPoints: any, index: number) => (
            <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? "#d3d8d8" : "" }}>
              <TableCell sx={{ fontWeight: "bold", fontSize: "0.9rem", padding: "0.8rem" }}>
                {ownerDetailsWithPoints.rank}
              </TableCell>
              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  padding: "0.8rem",
                }}
              >
                <Avatar
                  src={getImageUrlFromProfileImageField(ownerDetailsWithPoints?.ownerDetails?.profileImage)}
                  alt="Profile Picture"
                />
                {ownerDetailsWithPoints?.ownerDetails?.userName}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "0.9rem", padding: "0.8rem" }}>
                {ownerDetailsWithPoints?.totalPoints || 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RankTable;
